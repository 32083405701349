import React from "react";
import { Text } from "stormbreaker";

const SectionTitle = props => {
  return <Text variant="h2" color="mono700" textTransform="uppercase" letterSpacing="wide" align="center" fontWeight="lighter">{props.title}</Text>;
};

const MobileSectionTitle = props =>{
  return <Text variant="body3" color="mono700" textTransform="uppercase" letterSpacing="wide">{props.title}</Text>;
}

SectionTitle.defaultProps = {
  title: "section title"
};

SectionTitle.Mobile = MobileSectionTitle
export default SectionTitle;
