import React from 'react';
import { Box, Text, Container, Grid } from 'stormbreaker';
import { ReactComponent as CustomerCare } from '../../assets/customercare.svg';

function Banner() {
	return (
		<Box p={8} bg='mono800'>
			<Container maxWidth={1200}>
				<Grid>
					<Box display='flex' alignItems='center' justifyContent='space-between'>
						<Box width='60%' display='flex' alignItems='center'>
							<div>
								<CustomerCare />
							</div>
							<Text variant='h3' fontWeight='bold' color='white' ml={6}>
								For any design, manufacturing or quotation related queries
							</Text>
						</Box>
						<Box display='flex' flexDirection='column'>
							<Text variant='body3' fontWeight='normal' color='white' align='right'>
								Contact us at
							</Text>
							<Text color='white' fontWeight='bold' variant='h4' pt={2}>
								9946231123, 9447159965
							</Text>
						</Box>
					</Box>
				</Grid>
			</Container>
		</Box>
	);
}

export default Banner;
