import React,{Component} from 'react';
import Slider from "react-slick";

import "./Carousel.scss";

class Carousel extends Component {
  render() {
    const settings = {
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false,
      dots: false,
      infinite: true,
      speed: 700,
      delay: 500,
      fade: true,
      arrows: false,
      lazyLoad: true,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <Slider {...settings} {...this.props.settings}>
        {React.Children.map(this.props.children, child => {
          return (
            <div className="slider" style={{backgroundImage: `url(${child.props.img})`}}>
              {React.cloneElement(child)}
            </div>
          )
        })}
      </Slider>
    );
  }
}

export default Carousel