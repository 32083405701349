import React, { useContext, useRef, useState } from "react";
import { ContactUsContext } from '../../contexts/ContactUsContext'

import ContactUsMobile from './mobile'
import { motion } from "framer-motion";
import { Box, Text, TextInput, Button } from "stormbreaker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import {
  faCommentDots,
  faTimesCircle,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { apiPostHelper } from '../../Helper/apiHelper'

const Fab = styled(motion.div)`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  position: fixed;
  bottom: 50px;
  right: 50px;
  background: #c90000;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const CommentIcon = styled(FontAwesomeIcon)`
  font-size: 28px;
  color: white;
`;

const StyledSuccess = styled(FontAwesomeIcon)`
  font-size: 40px;
  color: ${props => props.theme.colors.success};
  margin-right: 12px;
`

const AnimatedForm = styled(motion.div)`
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 20px;
  width: 350px;
  overflow: hidden;
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 20;
`;

function ContactUs() {
  const [ open, setOpen ] = useContext(ContactUsContext);
  const [ loading, setLoading ] = useState(false)
  const [ submitSuccess, setSubmitSuccess ] = useState(false)

  let nameRef = useRef('')
  let emailRef = useRef('')
  let mobileRef = useRef('')
  let messageRef = useRef('')
  let fileRef = useRef('')

  const postFormDeatails = async (formData) => {
    try {
      const postResponse = await apiPostHelper('https://centroidserver.herokuapp.com/sendemail', formData)
      debugger;
      if (postResponse.data.status === 200)
        setSubmitSuccess(true)
      setLoading(false)
    }
    catch (error) {
      console.log(error, 'error')
      setLoading(false)
    }
  }
  const handleFormSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const formData = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      mobile: mobileRef.current.value,
      message: messageRef.current.value,
      // fileName: fileRef.current.files[ 0 ] ? fileRef.current.files[ 0 ].name : '',
      // filePath: fileRef.current.value,
    }

    postFormDeatails(formData)
  }
  return (
    <>
      <Fab
        animate={{ scale: open ? 0 : 1 }}
        bg="primary"
        title="For any questions"
        onClick={() => {
          setSubmitSuccess(false)
          setOpen(true)
        }}
      >
        <CommentIcon icon={faCommentDots} />
      </Fab>
      <AnimatedForm
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: open ? "auto" : 0,
          opacity: open ? 1 : 0,
          scale: open ? 1 : 0
        }}
      >
        <Box
          p={4}
          bg="mono800"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            variant="body2"
            fontWeight="medium"
            color="white"
            letterSpacing="wide"
          >
            FOR ANY ENQUIRY
          </Text>
          <FontAwesomeIcon
            onClick={() => setOpen(false)}
            style={{ fontSize: "20px", cursor: "pointer" }}
            icon={faTimesCircle}
            color="white"
          />
        </Box>

          <Box bg="mono50" py={5} px={4} minHeight={400}>
          {!submitSuccess ? (
            <form
              name="contact_us_form"
              // action="http://localhost:3000/sendemail"
              // method="POST"
              onSubmit={handleFormSubmit}
            >
              <Text
                letterSpacing="wide"
                as="label"
                color="mono600"
                variant="caption2"
                textTransform="uppercase"
              >
                Name OR Company name
          </Text>
              <Box mt={2} />
              <TextInput ref={nameRef} name="name" placeholder="Enter full Name" />
              <Box mt={4} />
              <Text
                as="label"
                color="mono600"
                variant="caption2"
                textTransform="uppercase"
                letterSpacing="wide"
              >
                Email id
          </Text>
              <Box mt={2} />
              <TextInput pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" ref={emailRef} name="email_address" type="email" placeholder="Enter email address" required />
              <Box mt={4} />
              <Text
                as="label"
                color="mono600"
                variant="caption2"
                textTransform="uppercase"
                letterSpacing="wide"
              >
                Mobile number
          </Text>
              <Box mt={2} />
              <TextInput ref={mobileRef} name="mobile_number" type="number" placeholder="Enter mobile number" required />
              <Box mt={4} />
              <Text
                as="label"
                color="mono600"
                variant="caption2"
                textTransform="uppercase"
                letterSpacing="wide"
              >
                Type your message here
          </Text>
              <Box mt={2} />
              <TextInput ref={messageRef} name="customer_enquiry" placeholder="Enter your enquiry" />
              {/* <Box mt={4} /> */}
              {/* <Text
            as="label"
            color="mono600"
            variant="caption2"
            textTransform="uppercase"
            letterSpacing="wide"
          >
            Upload a sample drawing
          </Text>
          <Box mt={2} />
          <TextInput ref={fileRef} type="file" name="uploaded_file" placeholder="upload a sample" /> */}
              <Box mt={8} />
              <Button loading={loading} as="button" type="submit" block size="large">
                GET A CALLBACK
          </Button>
            </form>
            )
            : (
              <Box pt={15} display="flex" flexDirection="column " alignItems='center' justifyContent="center">
                <Box display="flex" alignItems="Center">
                  <motion.div animate={{scale: 1.5 }} transition={{yoyo: Infinity, duration: 1}}>
                    <StyledSuccess icon={faCheckCircle} />
                  </motion.div>
                  <Text variant="h4" fontWeight="bold" color="mono900">Success</Text>
                </Box>
                <Text variant="body2" mt={10} align="center" color="mono400">Your enquiry has been submitted.<br /> Our customer executive will contact you soon.</Text>
                <Text style={{cursor: 'pointer'}} variant="body2" mt={5} align="center" color="accent500" onClick={()=> setSubmitSuccess(false)}>Add another enquiry</Text>
              </Box>
            )}
        </Box>
      </AnimatedForm>
    </>
  );
}

ContactUs.Mobile = ContactUsMobile

export default ContactUs;
