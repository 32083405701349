import React, { useEffect, useState } from "react";
import Header from "../../../src/Components/Header";

// import { Col, Container, Row } from "../../UIcomponents/Grid";
import SectionTitle from "../../UIcomponents/SectionTitle";
import { Button, Container, Grid } from "stormbreaker";
import Switch from "../../UIcomponents/Switch";

import {
  axiosGetHelper,
  axiosPostHelper,
  isEmpty
} from "../../Helper/apiHelper";

const AdminPage = () => {
  const init = {
    order_id: "",
    order_status: "",
    remarks: ""
  };
  const [orders, setOrders] = useState([]);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState(init);
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [disableUpdateBtn, setDisableUpdateBtn] = useState(false);

  useEffect(() => {
    setLoading(true);
    getOrders();
  }, [isUpdate]);

  const getOrders = async () => {
    try {
      const response = await axiosGetHelper(
        "https://centroidrealtimedb-9140.restdb.io/rest/orderstatus"
      );
      if (!isEmpty(response)) {
        setOrders(response.data);
        console.log(response, "response");
        setLoading(false);
      }
    } catch (e) {
      console.log("api error ", e);
    }
  };

  const addOrderStatusHandler = async () => {
    try {
      const orderStatusResponse = await axiosPostHelper(
        "https://centroidrealtimedb-9140.restdb.io/rest/orderstatus",
        values
      );
      if (!isEmpty(orderStatusResponse)) {
        setValues(init);
        setLoading(false);
        getOrders();
      }
    } catch (e) {
      console.log("api error ", e);
    }
  };

  function validateSerialNumber() {
    let error = {};
    const serialNumberExist = orders.find(function(val) {
      return val.order_id === values.order_id;
    });
    if (serialNumberExist && !isUpdate) {
      error.order_id = "Serial number already added";
      setErrors(error);
    } else if (!values.order_id.length) {
      error.order_id = "Serial number required";
      setErrors(error);
    } else if (
      !values.order_status.length &&
      values.order_status !== "select"
    ) {
      error.order_status = "Order status required";
      setErrors(error);
    } else {
      setErrors({});
      setLoading(true);
      addOrderStatusHandler();
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    validateSerialNumber();
  }

  const fetchOrderDetails = serialNum => {
    const order = orders.find(function(val) {
      return val.order_id === serialNum;
    });
    if (order) {
      setValues({ ...values, ...order });
      setDisableUpdateBtn(false);
    } else setValues({ ...values, ...init, "order_id": serialNum });
  };

  function handleChange(event) {
    setValues({ ...values, [event.target.name]: event.target.value });

    if (isUpdate && event.target.name === "order_id" && orders.length) {
      setDisableUpdateBtn(true);
      fetchOrderDetails(event.target.value);
    }
  }

  return (
    <>
      <Header />
      <Container>
        {/* <Row> */}
          <Grid>
            <div className="flex flex-middle mt-60">
              <p className="fs-16 fw-500 c-black-70 mr-16">For update order</p>
              <Switch
                isOn={isUpdate}
                handleToggle={() => setIsUpdate(!isUpdate)}
                activeColor="#36c"
              />
            </div>
            {isUpdate ? (
              <>
                <SectionTitle title="Update order details" />
                <form className="w-50p py-28">
                  <div className="flex flex-column mb-20">
                    <div className="fs-16 fw-500 pb-8">
                      Enter serial number to fetch order details
                    </div>
                    <input
                      name="order_id"
                      id="order_id"
                      type="text"
                      onChange={handleChange}
                      value={values.order_id}
                      className="no-outline ba br4 bc-grey-20 w-100p px-12 py-8 fs-16"
                      placeholder="Serial number"
                      required
                    />
                    {/* <p>{errors.order_id}</p> */}
                    {errors.order_id && (
                      <p className="c-red fs-12 pt-5 fw-500">
                        {errors.order_id}
                      </p>
                    )}
                  </div>

                  <div className="flex flex-column mb-20">
                    <div className="fs-16 fw-500 pb-8">Order status</div>
                    <select
                      name="order_status"
                      id="order_status"
                      value={values.order_status}
                      onChange={handleChange}
                      type="select"
                      className="no-outline ba br4 bc-grey-20 w-40p px-12 py-8 h-36 py-8 fs-16"
                      placeholder="Serial number"
                      required
                    >
                      <option default>Select</option>
                      <option id="1" value="Order received">
                        Order received
                      </option>
                      <option id="2" value="Die/mould work">
                        Die/mould work
                      </option>
                      <option id="3" value="Raw materials procured">
                        Raw materials procured
                      </option>
                      <option id="4" value="In production">
                        In production
                      </option>
                      <option id="5" value="In qa/qc">
                        In qa/qc
                      </option>
                      <option id="6" value="Final inspection and packing">
                        final inspection and packing
                      </option>
                      <option id="7" value="Dispatched">
                        Dispatched
                      </option>
                    </select>
                    {errors.order_status && (
                      <p className="c-red fs-12 pt-5 fw-500">
                        {errors.order_status}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-column mb-20">
                    <div className="fs-16 fw-500 pb-8">Remarks</div>
                    <textarea
                      name="remarks"
                      id=""
                      value={values.remarks}
                      onChange={handleChange}
                      type="text"
                      className="no-outline ba br4 bc-grey-20 w-100p px-12 py-8 fs-16"
                      placeholder="Remarks"
                      required
                    />
                  </div>
                  <Button
                    appearance="accent"
                    loading={loading}
                    onClick={handleSubmit}
                    disabled={disableUpdateBtn}
                  >
                    Update order status
                  </Button>
                </form>
              </>
            ) : (
              <>
                <SectionTitle title="Add order details" />
                <form className="w-50p py-28">
                  <div className="flex flex-column mb-20">
                    <div className="fs-16 fw-500 pb-8">Serial number</div>
                    <input
                      name="order_id"
                      id="order_id"
                      type="text"
                      onChange={handleChange}
                      value={values.order_id}
                      className="no-outline ba br4 bc-grey-20 w-100p px-12 py-8 fs-16"
                      placeholder="Serial number"
                      required
                    />
                    {/* <p>{errors.order_id}</p> */}
                    {errors.order_id && (
                      <p className="c-red fs-12 pt-5 fw-500">
                        {errors.order_id}
                      </p>
                    )}
                  </div>

                  <div className="flex flex-column mb-20">
                    <div className="fs-16 fw-500 pb-8">Order status</div>
                    <select
                      name="order_status"
                      id="order_status"
                      value={values.order_status}
                      onChange={handleChange}
                      type="select"
                      className="no-outline ba br4 bc-grey-20 w-40p px-12 py-8 h-36 py-8 fs-16"
                      placeholder="Serial number"
                      required
                    >
                      <option default>Select</option>
                      <option id="1" value="Order received">
                        Order received
                      </option>
                      <option id="2" value="Die/mould work">
                        Die/mould work
                      </option>
                      <option id="3" value="Raw materials procured">
                        Raw materials procured
                      </option>
                      <option id="4" value="In production">
                        In production
                      </option>
                      <option id="5" value="In qa/qc">
                        In qa/qc
                      </option>
                      <option id="6" value="Final inspection and packing">
                        final inspection and packing
                      </option>
                      <option id="7" value="Dispatched">
                        Dispatched
                      </option>
                    </select>
                    {errors.order_status && (
                      <p className="c-red fs-12 pt-5 fw-500">
                        {errors.order_status}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-column mb-20">
                    <div className="fs-16 fw-500 pb-8">Remarks</div>
                    <textarea
                      name="remarks"
                      id=""
                      value={values.remarks}
                      onChange={handleChange}
                      type="text"
                      className="no-outline ba br4 bc-grey-20 w-100p px-12 py-8 fs-16"
                      placeholder="Remarks"
                      required
                    />
                  </div>
                  <Button loading={loading} appearance="accent" onClick={handleSubmit}>
                    Add order status
                  </Button>
                </form>
              </>
            )}
          </Grid>
        {/* </Row> */}
      </Container>
    </>
  );
};

export default AdminPage;
