import React from 'react';
import { Box, Container, Grid, Text } from 'stormbreaker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import { faThumbsUp, faHandPeace, faDraftingCompass } from '@fortawesome/free-solid-svg-icons';

const StyledIcon = styled(FontAwesomeIcon)`
	font-size: 28px;
	color: ${(props) => (props.color ? props.theme.colors[props.color] : '')};
`;

function TopNotchMobile() {
	return (
		<Box px={4}>
			<Box display='flex'>
				<Box>
					<Box
						display='flex'
						alignItems='center'
						justifyContent='center'
						height={48}
						width={48}
						borderRadius={68}
						bg='mono50'
					>
						<StyledIcon icon={faThumbsUp} color='success700' />
					</Box>
				</Box>
				<Box pl={3}>
					<Text variant='body2' fontWeight='semibold'>
						100% quality
					</Text>
					<Box mt={1} />
					<Text variant='body1' fontWeight='light' color='mono400'>
						We use 100 % quality raw materials which are selected for the particular product and process.
					</Text>
				</Box>
			</Box>
			<Box mt={4} />
			<Box display='flex'>
				<Box>
					<Box
						display='flex'
						alignItems='center'
						justifyContent='center'
						height={48}
						width={48}
						borderRadius={68}
						bg='mono50'
					>
						<StyledIcon icon={faHandPeace} color='success700' />
					</Box>
				</Box>
				<Box pl={3}>
					<Text variant='body2' fontWeight='semibold'>
						Fully Customisable
					</Text>
					<Box mt={1} />
					<Text variant='body1' fontWeight='light' color='mono400'>
						We have a variety of raw materials ranging from Aerospace material to Medical material which is fully customised
						for the particular Part.
					</Text>
				</Box>
			</Box>

			<Box mt={4} />
			<Box display='flex'>
				<Box>
					<Box
						display='flex'
						alignItems='center'
						justifyContent='center'
						height={48}
						width={48}
						borderRadius={68}
						bg='mono50'
					>
						<StyledIcon icon={faDraftingCompass} color='success700' />
					</Box>
				</Box>
				<Box pl={3}>
					<Text variant='body2' fontWeight='semibold'>
						Design to Production
					</Text>
					<Box mt={1} />
					<Text variant='body1' fontWeight='light' color='mono400'>
						We have a dedicated design team to design, prototype and the production will be done only after customer approval.
					</Text>
				</Box>
			</Box>
		</Box>
	);
}

export default TopNotchMobile;
