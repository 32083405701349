import React, { useContext } from "react";
import { ProductCategoryContext } from "../../contexts/ProductCategoryContext";
import { Container, Grid } from "stormbreaker";
import CategoryCard from "./MobileCard";

function MobileProductCategory() {
  const [categoryList] = useContext(ProductCategoryContext);
  return (
    <Container>
      {categoryList.map(list => (
        <Grid span={12}>
          <CategoryCard
            name={list.name}
            key={list.id}
            description={list.description}
            imgUrl={[list.imgUrl]}
            categoryId={list.id}
          />
        </Grid>
      ))}
    </Container>
  );
}

export default MobileProductCategory;
