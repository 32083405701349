import React from 'react'
import styled from '@emotion/styled'

const StyleOverlay = styled('div')`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${props => props.zIndex ? props.zIndex : 60};
    background-color: rgba(0, 0, 0,0.2);
`

const Overlay = ({ zIndex, children }) => {
    return (
        <StyleOverlay zIndex={zIndex}> 
            {children}
        </StyleOverlay>
    )
}

export default Overlay
