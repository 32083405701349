import React, { useContext } from "react";
import {ContactUsContext} from '../../contexts/ContactUsContext'
import { Container, Box, Grid, Text, Button } from "stormbreaker";
import styled from "@emotion/styled";
import Slider from "../Slider";
import Header from "../Header";
import MobileProductDetails from './MobilePage'

const StyledImg = styled("img")`
  height: 400px;
  border-radius: 10px;
  object-fit: cover;
`;

function ProductDetails({ product }) {
  const { name, description, imgUrl, applications, features } = product;
  const [, setOpen]  = useContext(ContactUsContext)
  return (
    <>
      <Box>
        <Header />
        <Box height='90vh' bg="mono50" flex={1} style={{
        position: 'absolute',
        top: '-90px',
        transform: `skew(0deg,-5deg)`,
        width: '100vw',
        zIndex: -1
        // background: `linear-gradient(-150deg, #f2f2f2 15%, #f2f2f2 70%, white 94%)`
      }} />
        <Box mt={15} />
        <Container gap={10} maxWidth={1200}>
          <Grid span={6}>
            <Text variant="h2" fontWeight="bold" color="mono900">
              {name}
            </Text>
            <Box mt={4} />
            <Text
              variant="body2"
              color="mono"
              fontWeight="normal"
              lineHeight="copy"
            >
              {description}
            </Text>
            <Box mt={6} />
            <Button size="large" appearance="primary" onClick={()=> setOpen(true)}>
              Get quotes
            </Button>
          </Grid>
          <Grid span={6}>
            <Slider arrowSize={8}>
              {imgUrl.map(src => (
                <StyledImg src={src} alt={name} />
              ))}
            </Slider>
          </Grid>
        </Container>
        <Box mt={20} />
        <Container maxWidth="1200" gap={40}>
          {features ? (
            <Grid span={6}>
              <Box style={{overflow: 'hidden'}} borderRadius={10} bg="mono50">
                <Box flex={1} px={4} py={4} bg="primary400">
                  <Text variant="body2" color="white" letterSpacing="wide">FEATURES</Text>
                </Box>
                <Box p={4}>
                {features.map((list, index) => (
                <Box
                  py={2}
                  px={2}
                  bg="white"
                  display="flex"
                  alignItems="center"
                  my={2}
                  borderRadius={4}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={32}
                    height={32}
                    bg="mono200"
                    p={3}
                    mr={5}
                    color="white"
                    borderRadius={40}
                  >
                    <Text variant="body1" fontWeight="bold" color="white">
                      {index + 1}
                    </Text>
                  </Box>
                  <Text variant="body2" color="mono800">
                    {list}
                  </Text>
                </Box>
              ))}
                </Box>
              </Box>
            </Grid>
          ) : (
            <Box />
          )}
          {applications ? (
            <Grid span={6}>
              <Box style={{overflow: 'hidden'}} borderRadius={10} bg="mono50">
                <Box flex={1} px={4} py={4} bg="primary400">
                  <Text variant="body2" color="white" letterSpacing="wide">APPLICATIONS</Text>
                </Box>
                <Box p={4}>
                {applications.map((list, index) => (
                <Box
                  py={2}
                  px={2}
                  bg="white"
                  display="flex"
                  alignItems="center"
                  my={2}
                  borderRadius={4}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={32}
                    height={32}
                    bg="mono200"
                    p={3}
                    mr={5}
                    color="white"
                    borderRadius={40}
                  >
                    <Text variant="body1" fontWeight="bold" color="white">
                      {index + 1}
                    </Text>
                  </Box>
                  <Text variant="body2" color="mono800">
                    {list}
                  </Text>
                </Box>
              ))}
                </Box>
              </Box>
            </Grid>
          ) : (
            <Box />
          )}
        </Container>
        <Box mt={20} />
      </Box>
    </>
  );
}
ProductDetails.Mobile = MobileProductDetails
export default ProductDetails;
