import React, { useContext, useEffect } from 'react';
import { useParams, withRouter, useHistory } from 'react-router-dom';
import { ProductListContext } from '../../../contexts/ProductListContext';
import { ProductCategoryContext } from '../../../contexts/ProductCategoryContext';
import Header from '../../../Components/Header';
import { Container, Grid, Box } from 'stormbreaker';
import { Helmet } from 'react-helmet';
import SectionTitle from '../../../UIcomponents/SectionTitle';
import ProductCard from '../../../Components/ProductCard';
import Footer from '../../../Components/Footer';
// import ContactUs from '../../Components/ContactUs'

function MobileProductPage(props) {
	const history = useHistory();
	const { id } = useParams();
	const [productList] = useContext(ProductListContext);
	const [categoryList] = useContext(ProductCategoryContext);
	const category = categoryList.filter((list) => list.id === id);
	const pageTitle = category[0].name;

	const filteredList = productList.filter(function(list) {
		return id === list.categoryId;
	});

	useEffect(() => {
		if (!filteredList.length) {
			history.push('/');
		}
	}, [filteredList]);

	return (
		<>
			<Helmet>
				<title>Best quality {pageTitle}</title>
				<meta name='title' content={pageTitle} />
				<link rel='canonical' href={`https://centroidpolymer.com/products/${category[0].id}`} />
			</Helmet>
			<Header.Mobile />
			<Box
				height='70vh'
				bg='mono50'
				flex={1}
				style={{
					position: 'absolute',
					top: '-90px',
					transform: `skew(0deg,-5deg)`,
					width: '100vw',
					zIndex: -1,
					// background: `linear-gradient(-150deg, #f2f2f2 15%, #f2f2f2 70%, white 94%)`
				}}
			/>
			{/* <ContactUs /> */}
			<Box mt={7} />
			<Box px={4}>
				<SectionTitle.Mobile align='left' title={pageTitle} />
			</Box>

			<Box mt={5} />
			<Container>
				{filteredList.map((list) => (
					<Grid span={12}>
						<ProductCard.Mobile
							imgUrl={list.imgUrl}
							name={list.name}
							id={list.id}
							key={list.id}
							description={list.description}
						/>
						<Box mt={8} />
					</Grid>
				))}
			</Container>
      <Box mt={10} />
      <Footer.Mobile />
		</>
	);
}

export default withRouter(MobileProductPage);
