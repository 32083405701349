import React, { useContext } from "react";
import { Text, Button, Box, Container, Grid } from "stormbreaker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { ContactUsContext } from "../../contexts/ContactUsContext";
import {imgUrl} from '../../images'

import MobileLocatedAt from './MobilePage'

function LocatedAt() {
  const [, setOpen] = useContext(ContactUsContext)
  return (
    <Container
      gap={0}
      borderRadius={10}
      maxWidth={1200}
      style={{
        overflow: "hidden",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 8px 20px"
      }}
    >
      <Grid span={6}>
        <Box bg="mono" backgroundImage={`url(${imgUrl.location_img})`} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center" height={500}>
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.3971185504593!2d76.78510621483655!3d10.780865962059593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba86a29158827e1%3A0x4a819a7e01250a5f!2sCentroid%20Polymer%20Technologies!5e0!3m2!1sen!2sin!4v1587593448201!5m2!1sen!2sin"
            width="600"
            height="450"
            frameborder="0"
            style={{ border: 0, width: "100%", height: "500px" }}
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe> */}
        </Box>
      </Grid>
      <Grid span={6}>
        <Box bg="mono50" height={500} px={7} py={5}>
          <Text
            color="mono300"
            mb={3}
            variant="body2"
            fontWeight="normal"
            letterSpacing="wide"
            lineHeight="copy"
          >
            PALAKKAD KERALA
          </Text>
          <Text variant="h2" fontWeight="semibold">
            Centroid Polymer Technologies
          </Text>
          <Box border="3px solid" my={5} width={50} borderColor="primary300" />
          <Text
            color="mono600"
            variant="body2"
            fontWeight="medium"
            lineHeight="copy"
          >
            KINFRA Integrated Industrial & Textile Park Plot
          </Text>
          <Text
            color="mono600"
            variant="body2"
            fontWeight="medium"
            lineHeight="copy"
          >
            no P32(4),P32(5) Kanjikode
          </Text>
          <Text
            color="mono600"
            variant="body2"
            fontWeight="medium"
            lineHeight="copy"
          >
            Palakkad - 678621, Kerala, India
          </Text>
          <Box display="flex" alignItems="center" mt={7}>
            <FontAwesomeIcon icon={faEnvelope} />
            <Text
              href="mailto:info@centroidpolymer.com"
              color="mono800"
              pl={2}
              variant="body2"
              fontWeight="medium"
            >
              info@centroidpolymer.com
            </Text>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <FontAwesomeIcon icon={faPhoneAlt} />
            <Text
              href="tel:9946231123"
              color="mono800"
              pl={2}
              variant="body2"
              fontWeight="medium"
            >
              9946231123, 9447159965
            </Text>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <Text
              href="https://g.page/silicone-butyl-ptfe?share"
              color="accent700"
              pl={2}
              variant="body2"
              fontWeight="medium"
              target="_blank"
            >
              Google map link
            </Text>
          </Box>
          <Box mt={9}>
            <Button onClick={()=> setOpen(true)} outline>CONTACT US </Button>
          </Box>
          <Box mt={15}/>
          <Text variant="body1" color="mono300" fontWeight="light">*We will deliver our products all over India</Text>
        </Box>
      </Grid>
    </Container>
  );
}

LocatedAt.Mobile  = MobileLocatedAt
export default LocatedAt;
