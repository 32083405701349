import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Button, Container, Grid, Text, Box } from 'stormbreaker';

const SlideImg = styled(motion.div)`
	background-image: url(${(props) => props.backgroundImage});
	height: 180px;
	width: 100%;
	background-size: ${(props) => props.backgroundSize};
	background-position: ${(props) => props.backgroundPosition};
	background-repeat: ${(props) => props.backgroundRepeat};
	background-color: ${(props) => props.theme.colors[props.bg]};
	filter: contrast(120%);
`;

function MobileSlide(props) {
	return (
		<Box >
			<Box
				height={180}
				width='100%'
				style={{
					overflow: 'hidden',
				}}
			>
				<SlideImg
					animate={{ scale: 1.2 }}
					transition={{
						yoyo: Infinity,
						duration: 10,
						linear: 'linear',
					}}
					bg='mono100'
					backgroundSize='cover'
					backgroundPosition='center'
					backgroundRepeat='no-repeat'
					backgroundImage={props.img}
				/>
			</Box>
      <Box
      px={4}
          // width={500}
          mt={3}
					display='flex'
					flexDirection='column'
					alignItems='left'
					// ml={`calc((100vw - 1200px)/2)`}
				>
					<Box>
						<Text style={{fontSize: "20px", lineHeight: "28px"}} variant='h1' textTransform="uppercase" letterSpacing='wide' color='mono900' fontWeight='normal'>
							{props.title}
						</Text>
						<Text mt={3} variant='body1' color='mono700' fontWeight='light'>
							{props.label}
						</Text>
					</Box>
				</Box>
		</Box>
	);
}

export default MobileSlide;
