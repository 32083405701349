import React, { useContext } from "react";
import { ProductCategoryContext } from "../../contexts/ProductCategoryContext";
import { Container, Grid } from "stormbreaker";
import CategoryCard from "./Card";

import MobileProductCategory from './MobileProductCategory'

function ProductCategory() {
  const [categoryList] = useContext(ProductCategoryContext);
  return (
    <Container gap={20} maxWidth={1200}>
      {categoryList.map(list => (
        <Grid span={4}>
          <CategoryCard
            name={list.name}
            key={list.id}
            description={list.description}
            imgUrl={list.imgUrl}
            categoryId={list.id}
          />
        </Grid>
      ))}
    </Container>
  );
}


ProductCategory.Mobile = MobileProductCategory

export default ProductCategory;
