import React, { createContext, useState } from "react";
import { imgUrl } from '../images'

export const ProductListContext = createContext([ [], () => { } ]);

function ProductListContextProvider(props) {
  const productList = [
    {
      categoryId: "silicone_tubings",
      name: "Platinum grade silicone tubing",
      id: "platinum_grade_silicone_tubing",
      description:
        "Platinum grade silicone tubing are used commonly in pharma appliacations due to its Biocompatible, inertness and high purity",
      features: [
        " Provided for both regulatory and non-regulator market according to customer.",
        " Over-molded connectors to separate lumen into individual tubes.",
        " Platinum cured for a high degree of purity.",
        " Extended post cure for lowest level extractables.",
        " Biocompatible, inert, non-reactive with other elements per systemic testing.",
        " Odorless, tasteless, non-toxic for use in medical, food, drug, personal care, and deionized water applications.",
        " Non-leaching plasticizers.",
        " Sterilizable by radiation, EtO, steam – 0.2 MPa at +122 °C / 30 psi at +253 °F.",
        " Resist temperature extremes – flexibility retention: -53 °C to +226 °C / -65 °F to +440 °F.",
        " Translucent for visual monitoring."
      ],
      application:
        "Healthcare & Medical applications:Catheters, electronics, analytical equipment, fluid transfer, drug delivery, medical instrumentation, chemical and analytical analysis, fermentation in biotech industries, sampling, lab purpose, critical transfer and filling",
      imgUrl: imgUrl.tubing_platinum_grade
    },
    {
      categoryId: "silicone_tubings",
      name: "Medical grade silicone tubing & hose",
      id: 'medical_grade_silicone_tubing_and_hose',
      description:
        "These are tubes made of High purity, performance and biocompatibility type silicone",
      features: [
        " Platinum cured for a high degree of purity",
        " Extended post-cure for lowest levels of extractables",
        " Bio-compatible, inert, non-reactive with other elements per systemic testing",
        " Odorless, tasteless, non-toxic for use in medical, food, drug, personal care, and deionized water applications",
        " Non-leaching plasticizers",
        " Sterilizable by radiation, EtO, steam – 0.2 MPa at 122°C / 30 psi at +253 °F for use in sterile applications",
        "Resists temperature extremes and retains flexibility:-18 °C to +226 °C / -65 °F to +440°F",
        "Translucent for visual monitoring",
        " Color stripe for custom process control identification "
      ],
      applications: [ "Typical Healthcare & Medical applications:Respiratory devices, peristaltic pumps, fluid handling devices, bioprocess containers & single-use products, high purity water and process systems, sterile filtration and final filling, protein purification, buffer and media preparation, peristaltic pumps and cross flow filtration systems, cell culture systems, diagnostic reagents, dental, drug delivery, pharmaceutical production, surgical devices, orthopaedics and prosthetics." ],
      imgUrl: imgUrl.tubing_medical_grade
    },
    {
      categoryId: "silicone_tubings",
      id: "food_grade_silicone_tubing",
      name: "Food grade silicone tubing",
      description:
        "We provide food grade silicone Tubings from silicone rubber which is food grade quality with FDA certificate .If you are searching for rubber tubing that can take the heat and food grade, silicone Tubing won’t impart taste or odors and maintains its flexibility over time, use with water, air, and food and beverage.",
      features: [
        " Tubing is made from FDA-compliant rubber.",
        " Material meets ASTM , FDA and other food grade standards as proposed by the customer.",
        " We are sourcing our raw materials from all over the world to meet the customer requirements with prominent manufacturers such as wacker, momentive, shinetsu etc. ",
        " Tubing can be sterilized with steam (autoclaving) and radiation.",
        " Manufactured under good manufacturing procedures in a controlled environment",
        " Free of BPA, latex and phthalates; RoHS compliant",
        " Any colour or custom RAL colour available",
        " food-grade silicone tubing is translucent, allowing you to easily see the flow",
        " High temperature tubing is tasteless, odorless, and has been temperature rated from -20 Celsius(-4 F) to 260 Celsius(500 degrees Fahrenheit)",
        " Perfect for personal breweries, especially for applications where wort will be above 180 degrees Fahrenheit",
        " Compatible with magnetic drive pumps like March or Chugger",
        " Custom color, size and length available."
      ],
      Application: [
        " Used in hot water lines of food processing machineries",
        "Used in vending machines like coffee vending machines, hot milk vending machines",
        "Used in water dispensers, water purifiers",
        "Used as diary tubing"
      ],
      imgUrl: imgUrl.tubing_food_grade
    },
    {
      categoryId: "silicone_tubings",
      id: "Custom_Peristaltic_Pump_Tubing",
      name: "Custom Peristaltic Pump Tubing",
      description:
        "These tubes are used in High performance silicone pump solutions such as in peristaltic pumps.Our custom peristaltic pump tubing materials are made using special grades of high performance silicone which help the pump achieve consistent flow rates while reducing the effects of hysteresis and spallation.",
      features: [
        " Designed to meet your specifications",
        " Offered in flexible and chemically resistant silicone tubing",
        " Stops can be added using bonding or over-molding",
        " The key advantage of peristaltic pumps is their disposable pump tubing",
        " Only the tubing inner diameter comes into contact with the fluid, and therefore there is no need to clean or flush the pump in between uses.",
        " Molded stops can also be added to the tubing to lock it in place and keep it from rotating."
      ],
      application:
        "Typical Healthcare & Medical applications:IV pumps, enteral feeding tubes, laboratory equipment, diagnostic equipment, bio-processing fluid transfer.Due to the inertness to ozone silicone tubings are compatible to be used in ozone generators. We supply the best quality tubes for ozone generators in best price.",
      imgUrl: imgUrl.tubing_peristaltic_pump
    },
    {
      categoryId: "silicone_tubings",
      id: "Silicone_sleeves_or_thin_walled_tubes",
      name: "Silicone sleeves or thin walled tubes",
      description:
        "Silicone sleeves are of High Electrical insulation, performance and can be made flame retardant",
      features: [
        " High dielectric Strength",
        " Provided in flame retardant compound like UL  or FL standards",
        " Meet RoHS standards",
        " Non-leaching plasticizers",
        " Flexible and light weight",
        " Resists temperature extremes and retains flexibility:-18 °C to +226 °C / -65 °F to +440°F",
        " Translucent for visual monitoring, any colour"
      ],
      application:
        "Typical Electrical applications:Transformer sleevings, sleevings for wire harness and cable cover, sleeves for machines, electrical equipments.",
      imgUrl: imgUrl.tubing_silicone_sleeves
    },
    {
      categoryId: "silicone_tubings",
      id: "Corona_Treater_Sleeves",
      name: "Corona Treater Sleeves",
      description:
        "Our silicone corona treater sleeves provides High Electrical insulation, excellent chemical and thermal resistance",
      features: [
        " High dielectric Strength",
        " Provided in flame retardant compound like UL  or FL standards",
        " Meet RoHS standards",
        " Non-leaching plasticizers",
        " Flexible and light weight",
        " Resists temperature extremes and retains flexibility:-18 °C to +226 °C / -65 °F to +440°F",
        " Translucent for visual monitoring, any colour",
        " Also traction sleeves"
      ],
      application:
        "Used in sleeves for rollers in corona treaters, FDA complaint, Easy to install. In traction sleeves for rolls in packaging industries like shrink tunnel machines",
      imgUrl: imgUrl.tubing_corona_treater
    },
    {
      categoryId: "silicone_hoses",
      id: "Silicone_Braided_Hose",
      name: "Silicone Braided Hose",
      description:
        "We manufacture silicone food grade braided hoses with FDA or customer required certification.Our Silicone hoses are of best quality and of good strength.The unique combination of elastomer and polyester in a position to offer its customers a broad range of textile-reinforced pressure hoses for many different high-pressure or vacuum applications. The multi-stage production process combines an extruded silicone inner hose with a surrounding braid of the various different possible textiles and, on request, a coloured silicone coating. This sandwich structure guarantees pressure resistances of up to more than 100 bar with multiple reinforcements with an additional intermediate silicone ply. A specific selection of nominal widths with inner diameters from 3mm (1/8 inch) to 50m (2 inch) is available exstock.",
      features: [
        " Suitable for sterilisation (e.g. with steam, ethylene oxide, radiation ).",
        " Meet the hygiene requirements of FDA, Pharmacopoeia, BfR, EC directives",
        " Food grade hoses are available.",
        "Greater Flexibility",
        " Imparts no taste and odor",
        " Manufactured from high quality silicone rubber",
        " Coloured outer layer available for coding",
        " High tear resistance, easy to install",
        " Resistant to UV, Ozone, Oxygen and steam"
      ],
      applications: [
        "Medical technology (e.g. dialysis machines)",
        "Plant construction (e.g. coolant pipes)",
        "Machine construction (e.g. suction and filling hoses) ",
        "Industrial kitchen technology (e.g. hot water and steam cooking hoses)",
        "Food industry (e.g. transport lines conforming with food product requirements)",
        "Emergency supply systems (e.g. temperature-adjusted respiration hoses) ",
        "Bio-technology (e.g. permeable fermentation pipes)",
        "Wastewater technology (e.g. gassing hoses) ",
        "Aerospace engineering (e.g. temperature-resistant compressed air pipes) ",
        "Communications engineering (e.g. protection hoses for cold light carriers)",
        "Diary hoses(e.g Hoses in milDrug manufacturing Machines,biotechnology"
      ],
      imgUrl: imgUrl.silicone_Braided_Hose
    },
    {
      categoryId: "silicone_hoses",
      id: "Silicone_Heater_hose",
      name: "Silicone Heater hose",
      description:
        "Silicone Extreme High Temperature Heater Hose is designed for use in heater and radiator systems in heavy duty/high capacity vehicles and industrial equipment in applications to 400°F(200 C) and 80 psi or 5.5 bar.",
      features: [
        " polyester braid reinforcement – 70 Durometer",
        " According to SAEJ20 standard",
        " Available in Blue or Black color",
        " Recommended working temperatures: -65° to 350°F (-53.9°C to +176.6°C).",
        " Resistant to electrochemical degradation.",
        " Meets and exceeds all performance and physical criteria of SAE J20 R3 and various other heavy-duty OEM specifications.",
        " Excellent ozone and UV resistance."
      ],
      applications: [
        "Air intake connections",
        "supercharger connections",
        "turbo and intercooler drain lines",
        "radiator or heater core connections or virtually any application where high temperatures or extreme working conditions exist Used in aerospace applications",
        "performance motors and vehicles",
        "marine wet –exhaust, commercial truck and bus induction and cooling systems, agricultural and off highway vehicles, all turbo diesel applications, food and beverage connections and general manufacturing industries worldwide."
      ],
      imgUrl: imgUrl.silicone_heater_hose
    },
    {
      categoryId: "ptfe_teflone",
      id: "PTFE_Teflon_Moulded_cylinders",
      name: "PTFE/Teflon Moulded cylinders",
      description:
        "We manufacture Molded PTFE Cylinders are generally used as machining stock to create shapes for any number of chemical, electrical and mechanical components where the outstanding performance characteristics of PTFE are required. We also provide PTFE/ Teflon rod Other applications are seals, submersible pump components, lining systems and many other applications in the Oil & Gas, Chemical, Industrial and Electronic markets. Molded PTFE Cylinders can be manufactured using: Virgin PTFE – excellent thermal, chemical, and electrical resistant properties. Super Modified PTFE – virgin PTFE modified to exhibit superior physical properties. (Filled PTFE) – PTFE blended with specific additives to suit specific applications",
      features: [
        "Custom Diameter available",
        "Custom Length available",
        "Virgin or filled grade material available",
        "Good Chemical resisitance",
        "Good surface finish"
      ],
      applications: [
        "Petrochemical and chemical processing: PTFE is the material of choice for gaskets, vessel linings, pump interiors, washers, rings, seals, spacers, dip tubes and well-drilling components,Pumps and Valves",
        "Electrical applications: PTFE is one of the best insulators known. In thin sections, it will insulate to 500 volts per mil. There are grades of PTFE which have even greater dielectric strength. It is frequently used in wire and cable wrap, and to separate conductive surfaces in capacitors.",
        "Food, Beverage and Pharmaceutical industries: Virgin PTFE is approved by the Food and Drug Administration for use in the food, beverage, cosmetics and pharmaceutical industries. Thin Film and sheets make an inert, no-toxic slide surface without microscopic depressions where microbes can grow. Conveyancing components - profiles, guide rails and slides - can withstand high temperatures inside baking and drying ovens and other heated segments of the food, cosmetics or pharmaceuticals manufacturing processes.",
        "Laboratory applications: PTFE piping, tubing and vessels are used in various laboratory ware because of their chemical resistance, and inertness.",
        "Bearing pads: Glass filled PTFE can be cut or stamped into bearing pads, and will resist all weather-related degradation while remaining an inert interface between disparate construction materials, like steel and concrete."
      ],
      imgUrl: imgUrl.ptfe_molded_cylinders
    },
    {
      categoryId: "ptfe_teflone",
      id: "PTFE_Custom_Machined_parts",
      name: "PTFE Custom Machined parts",
      description:
        "We provide PTFE /Teflon custom machined parts such as gaskets,seals",
      features: [ "", "", "" ],
      applications: [ "", "", "" ],
      imgUrl: imgUrl.ptfe_machined_parts
    },
    {
      categoryId: "PTFE_TEFLON",
      id: "PTFE_rings_and_Seals",
      name: "PTFE rings and Seals",
      description:
        "Centroid Polymer Technologies has become the trusted manufacturer for specialty PTFE/Teflon  O-Rings and Seals of all types and sizes. While we manufacture Seals and O-Rings out of many materials, PTFE/Teflon is by far the most popular. PTFE Seals and O-Rings offer extreme temperature, high pressure, and chemically inert properties for the most demanding applications. We are dedicated to the manufacture of high performance sealing solutions and bearings to suit your specific needs. Seals can be provided in a range of PTFE and high performance polymer materials, specified to meet and exceed the demands of the application. We offer custom solutions for use throughout many industries including chemical, pharmaceutical, food processing, aerospace, automotive and offshore.",
      features: [
        "Excellent chemical resistance ",
        "Wide operating temperature range",
        "Very low coefficient of friction ",
        "High resistance to wear ",
        "No stick/slip effect ",
        "Good dry running properties",
        "Whether a standard seal or a custom requirement, our dedicated technical team can provide you with innovative and effective solutions to your needs."
      ],
      applications: [ "", "", "" ],
      imgUrl: imgUrl.ptfe_orings
    },
    {
      categoryId: "ptfe_teflone",
      id: "PTFE_Teflon_skived_strips_sheets_and_tapes",
      name: "PTFE/Teflon skived strips,sheets and tapes",
      description:
        "PTFE skived Strips,sheets & Tape features superior insulating strength, is non-stick, flexible and resists high temperatures, chemicals and moisture. Other properties include zero porosity, excellent release and of course, a super-low coefficient of friction. Skived PTFE sheets and films are available in a wide range of materials and in continuous rolls or cut to your desired length",
      features: [
        "Manufactured from High quality raw materials",
        "Good Electrical and chemical resistance",
        "We can provide FDA grade, RoHS complaint material"
      ],
      applications: [
        "Aeronautical",
        "Atomic/Nuclear",
        "Chemical, Oil & Gas",
        "High voltage Electrical Applications",
        "Petrochemical",
        "Pump and valve manufacturing"
      ],
      imgUrl:  imgUrl.ptfe_skived_strips
    },
    {
      categoryId: "ptfe_teflone",
      id: "PTFE_Teflon_guide_strips_wear_rings",
      name: "PTFE/Teflon guide strips, wear rings",
      description: "",
      features: [ "", "", "" ],
      applications: [ "", "", "" ],
      imgUrl: imgUrl.ptfe_wear_rings
    },
    {
      categoryId: "ptfe_teflone",
      id: "ptfe_valve_seats",
      name: "PTFE Valve seats",
      description:
        "PTFE  Valve Seats are available in a range of dimensions and profiles and are customisable based on customer requirements and OEM drawings. Aside from standard parts, we also manufacture customised components to suit specific customer requirements and end-use applications. ",
      features: [
        "Low coefficient of friction",
        "Excellent chemical resistance",
        "Higher compressive strength",
        "Filled and unfilled material available"
      ],
      applications: [
        "PTFE and RPTFE back-up rings ",
        "PTFE Thrust washers ",
        " PTFE Ball Valve Seats ",
        "PTFE Butterfly Valve Seats ",
        "PTFE Piston Ring Seals ",
        "PTFE Rotary Shaft Seals ",
        "PTFE Spring-energised Seals",
        "PTFE Actuator Joint Bush ",
        "PTFE/RPTFE Specialised Gaskets"
      ],
      imgUrl: imgUrl.ptfe_valved_seats
    },
    {
      categoryId: "silicone_extrusions",
      id: "Silicone_Conductor_cover",
      name: "Silicone Conductor cover",
      description:
        "We are one of the leading/best silicone overhead/line cover or sleeve manufacturer from India with good quality and cost. The Insulated Conductor Covers are extruded silicone rubber products that are designed for field installation over bare conductors and metal mounting brackets. These covers provide a layer of electrical insulation for phase-to-phase and phase-to-ground protection from bird contact. They are cut to length in the field for a proper fit.Silicone conductor covers are designed to provide protection for both equipment and birds. The conductor covers are designed to insulate bare aerial conductors from adjacent phases and grounded equipment. The covers are made with a locking type profile which makes it easy on installation by pressing into the lock without any tools. The covers are offered in Custom thickness sizes for covering 10kV,15 kV,33kV and 66 kV- rated conductors. The products are available in ACSR conductor sizes from 2 AWG to 266 kcmil. We have flame retardant or V-0 material solutions for conductor cover.",
      features: [
        " Excellent UV resistance ",
        " Excellent arc resistance ",
        " Excellent ozone resistance ",
        " No special tools required in installation.",
        " Good cold weather installation.",
        " All standard colours available (standard Grey, Brick Red, Red, Yellow, Blue etc).",
        " High Dielectric Strength and Volume resistivity."
      ],
      applications: [
        "For ACSR conductors ",
        "For Dog, Moose, Panther, Ant, Leopard etc",
        "Used in 11 kV, 33 kV, 66 kV,110kV, 220kV",
        "Protection of Birds and wildlife",
        "performance motors and vehicles"
      ],
      imgUrl: imgUrl.silicone_conductor_cover
    },
    {
      categoryId: "silicone_extrusions",
      id: "Silicone_sponge_or_foam",
      name: "Silicone sponge or foam",
      description: "We manufacture silicone sponge profile tailored to your exact specifications. Owing to the varying complexities being evaluated on an individual basis, silicone sponge profiles are available in all custom-made designs. We provide the silicone sponges for clean rooms, telecom shelters, air tight door seal applications, bakery ovens, freeze doors, autoclaves, isolators, dry heat sterilisers and more. The most common profiles include square, rectangle,T-Sections,Blade/Leaf Seals,Arrowheads,  Bubble Seals, Door Seals e-Sections,  H-Sections,   Hollow D-Sections,Hollow Strips, Keyhole Sections,  L-Sections, Lip Seals, Omega Sections, P-Sections (Double,Large, Medium, small), Sponge D-Sections, sponge Square Sections, Strip Sections,   Autoclave Seals, Tadpole Sections, Trapeze Sections, Triangle Seals, U Channels (Large,Medium,Small, Rounded), Window Seals, Tank Seal,  Silicone Inflatable Seals ",
      features: [
        " Excellent UV, arc, ozone resistance ",
        " Any custom shape and colour can be supplied.",
        " Good cold weather installation.",
        " High tensile, high tear,steam resistant,low temperature, high temperature, flame retardant(UL grade), conductive, semi-conductive,medical, pharma grade, food grade,oil bleeding,anti-static,platinum cured and special grades or customised grades",
        "FDA approved, RoHS compliant"
      ],
      applications: [
        "Aerospace : Lighting Gaskets, Vent Ducts, Door Seals, HVAC Seals, Windows Seals,Thermal Insulation, Instrument Panel Seals, Engine Gaskets, Airframe sealing ",
        "Automotive : Engine Gaskets, Ignition Cables, Performance Hoses, Window Seals, Door Seals, Interior Seals",
        "Construction : Door Seals, Inflatable Door Seals,Window Seals, Panel Seals, Piping Seals, Tank Seals",
        "Marine : Extruded profiles,Tubing and cord, Marine gaskets, Marine door gaskets, Window glazing gaskets, Formed hose, Hatch seals, Gaskets for ships and submarines, Sponge or foam seals",
        "Ovens and Appliances : Cords, Strips, Bespoke Profiles, Joined rings, Moulded parts, Silicone Sheeting",
        "Medical : Autoclave gaskets, Inflatable gaskets, Modular OT gaskets, Panel gaskets, Pharma and equipment profiles.",
        "Rails and Mass transit :Heating, ventilation and air-conditioning (HVAC) gaskets, Interior and exterior lighting gaskets, Display and electrical systems gaskets, Interior and exterior door seals, Window Seals",
        "Others : Custom profiles used in food processing machines, Lighting gaskets, Joined and inflatable gaskets"
      ],
      imgUrl: imgUrl.silicone_sponge
    },


    {
      categoryId: "silicone_extrusions",
      id: "Silicone_Profiles",
      name: "Silicone Profiles",
      description:
        "We offers custom extruded shapes of silicone rubber profiles in more than 2000 different shapes, size and colour. We provide the silicone profiles for clean rooms, telecom shelters, air tight door seal applications, bakery ovens, freeze doors, autoclaves, isolators, dry heat sterilisers and more. The most common profiles include square, rectangle,T-Sections,Blade/Leaf Seals,Arrowheads,  Bubble Seals, Door Seals e-Sections,  H-Sections,   Hollow D-Sections,Hollow Strips, Keyhole Sections,  L-Sections, Lip Seals, Omega Sections, P-Sections (Double,Large, Medium, small, Solid), Solid D-Sections, Solid Square Sections, Strip Sections,   Autoclave Seals, Tadpole Sections, Trapeze Sections, Triangle Seals, U Channels (Large,Medium,Small, Rounded), Window Seals, Tank Seal,  Silicone Inflatable Seals,  Silicone Sieve Seals,  Silicone Rubber Cord, Clip on Silicone U Channel,Silicone Sponge Extrusions, Treater Rubber Sleeves ",
      features: [
        " Excellent UV, arc, ozone resistance ",
        " Any custom shape and colour can be supplied.",
        " Good cold weather installation.",
        " High tensile, high tear,steam resisitant,low temperature, high temperature, flame retardant(UL grade), conductive, semi-conductive,medical, pharma grade, food grade,oil bleeding,anti-static,platinum cured and special grades or customised grades",
        "FDA approved, RoHS complaint"
      ],
      applications: [
        "For ACSR conductors ",
        "For Dog, Moose, Panther, Ant, Leopard etc",
        "Used in 11 kV, 33 kV, 66 kV,110kV, 220kV",
        "Protection of Birds and wildlife",
        "performance motors and vehicles"
      ],
      imgUrl: imgUrl.silicone_profiles
    },
    {
      categoryId: "silicone_moldings",
      id: "Silicone_Bellows_Diaphragms_and_Boots",
      name: "Silicone Bellows, Diaphragms and Boots",
      description:
        "We make high quality silicone bellows customised used for protection in various technical specifications. Silicone Bellows withstand high temperatures , good inertness to chemicals and resistant to weatherability like ozone and UV. We can provide both Medical grade, food grade, fire resistant grade, conductive or semi-conductive.Also known as moulded silicone bellows, silicone rubber bellows, silicone weighing bellow, silicone expansion bellow",
      features: [
        " Sustain Ozone , UV and all sterlization ",
        "  Meets FDA, Medical Standards.",
        "  Straight or right angled bushing boot (sealing sleeve) .",
        "  Excellent Sealing.",
        "No need for specialized tools"
      ],
      applications: [
        "  Silicone Bellows for dust cover",
        "   Silicone dust sheath for tube",
        "Boots and Bellows in electrical systems .",
        " Flexible Silicone Rubber Bushing Boot used on screened polymeric or rubber insulated cables of 7.2 to 12kV.",
        " Pharma and medical equipment(ventilators)"
      ],
      imgUrl: imgUrl.silicone_bellows_expansion
    },
    {
      categoryId: "silicone_moldings",
      id: "Silicone_Custom_Mouldings",
      name: "Silicone Custom Mouldings",
      description:
        "We make high quality silicone bellows customised used for protection in various technical specifications. Silicone Bellows withstand high temperatures , good inertness to chemicals and resistant to weatherability like ozone and UV. We can provide both Medical grade, food grade, fire resistant grade, conductive or semi-conductive. Also known as moulded silicone bellows, silicone rubber bellows, silicone weighing bellow, silicone expansion bellow",
      features: [
        " Sustain Ozone , UV and all sterlization ",
        "  Meets FDA, Medical Standards.",
        "  Straight or right angled bushing boot (sealing sleeve) .",
        "  Excellent Sealing",
        " No need for specialized tools"
      ],
      applications: [
        "  Silicone Bellows for dust cover",
        "   Silicone dust sheath for tube",
        "Boots and Bellows in electrical systems .",
        "Flexible Silicone Rubber Bushing Boot used on screened polymeric or rubber insulated cables of 7.2 to 12kV.",
        "Pharma and medical equipment(ventilators)."
      ],
      imgUrl: imgUrl.silicone_custom_moldings
    },
    {
      categoryId: "silicone_moldings",
      id: "Silicone_Triclover_gaskets",
      name: "Silicone Triclover gaskets",
      description:
        "Silicone Tri-Clover gaskets also known as sanitary gaskets, hygienic gasket seals, tri-clamp gaskets are designed to meet the needs of pipe couplings in food, beverage and pharmaceutical process lines.Types are T/C gaskets, Mesh T/C gaskets,PTFE gaskets, Sensor T/C gaskets.",
      features: [
        " Available in EPDM, Silicone (VMQ), FKM, FFKM and metal detectable elastomer materials ",
        "  Meets FDA, Medical Standards,Ro Hs complaint.",
        "  Free from animal-derived ingredients (ADI-free) .",
        "  Optimal chemical and temperature resistance.",
        "Parts manufactured to BS4825, ISO2852 and DIN32676 standard sizes",
        "Certificates of conformity on request"
      ],
      applications: [
        "  Food and drug plant hoses.",
        " Used for sealing TC flanges",
        "Biotech, Industrial,Pharma, Brewing,.",
        "Essential oil extraction, Diary processing."
      ],
      imgUrl: imgUrl.silicone_triclover_gaskets
    },
    {
      categoryId: "silicone_moldings",
      id: "Silicone_o_rings_and_seals",
      name: "Silicone o rings and seals",
      description:
        "Silicone o-rings are excellent seal materials for high temperatures in static environments. Typically, silicone o-rings show outstanding flex and fatigue life, strong ozone and UV radiation resistance, and physiologically neutral and insulating properties. Silicone o-rings can be synthesized with a wide variety of properties and compositions, allowing for great flexibility in performance.",
      features: [
        "High temperature silicone gaskets and o-rings",
        "  Meets FDA, Medical Standards,RoHs complaint.",
        "  Free from animal-derived ingredients (ADI-free) .",
        "  Extreme temperature range and low temperature flexibility.",
        "Silicone seals and o rings are Resistant to hot air, ozone, UV radiation",
        "Silicone can be compounded to be electrically resistant, conductive, or flame retardant."
      ],
      applications: [
        " food ,pharma, medical and semiconductor applications, because they do not impart odor or taste",
        " fluorosilicone O-rings are primarily used in fuel systems",
        "Hydraulic and pneumatic fittings and seals,."
      ],
      imgUrl: imgUrl.silicone_orings
    },
    {
      categoryId: "silicone_moldings",
      id: "Silicone_Overmoulded_and_Handles",
      name: "Silicone Overmoulded and Handles",
      description:
        "Rubber-to-metal bonding is a molding rubber process where rubber is bonded onto a metal insert. To form the bond, the metal inserts are coated in adhesive and then placed into a mold. After the mold closes, heated rubber flows around the metal insert and attaches to the metal thus providing better quality and desired product.",
      features: [
        "High chemical resistance for a long service life",
        "  Meets FDA, Medical Standards,RoHs complaint.",
        "  Autoclavable or sterilisable",
        "  Extreme temperature range and low temperature flexibility.",
        "Silicone mouldings are Resistant to hot air, ozone, UV radiation"
      ],
      applications: [
        "Silicone Handles for Medical Applications",
        "Silicone Handles for Medical Instruments",
        "Overmoulded parts in Automotive and electrical applications."
      ],
      imgUrl: imgUrl.silicone_overmolding
    },
    {
      categoryId: "mastic_tapes",
      id: "Black_Mastic_Tape",
      name: "Black Mastic Tape",
      description:
        "Also known as self amalgamating tapes,Used in filling the cable cores for insulation , sealing and waterproof performances. Suitable for use in 11KV, 25KV and 36KV joints and terminations.The unique waterproof tape for telecommunication, it consists of Butyl Rubber, with performance of adhesion, shape and super waterproof, it is also resistant to corrosion for Copper and various cable sheathing materials. The tape is suitable for water seal with all kinds of telecommunication [equipment] and cable connectors rated at 90℃ with an emergency overload rating of 130℃. ",
      features: [
        "Non reactive.",
        "  Self healing effect.",
        "  Permanently tacky ",
        "  Long term resistant.",
        "Good temperature resistance: -30 to +90°C ",
        "Good adhesion to almost all substrates.",
        "Good ageing and weather resistance.",
        "Environmentally friendly in production, application and disposal.",
        "Sealants for interior and exterior application."
      ],
      applications: [
        "Antenna, RRU Connection Waterproof.",
        "Earth Wire connection Waterproof.",
        "Macro station and special application.",
        "Suitable for used in 11KV, 25KV and 36KV joints and terminations."
      ],
      imgUrl: imgUrl.black_mastic_tape
    },
    {
      categoryId: "mastic_tapes",
      id: "Red_Mastic_Anti_tracking_Sealant",
      name: "Red Mastic Anti tracking Sealant",
      description:
        "Used to fill the spacing between cable cores for insulation , sealing and waterproof performances. Suitable for used in 11KV, 25KV and 36KV joints and terminations. Anti-tracking mastic provide sealing and anti-tracking for power cable termination and joints for heat shrink and cold shrink technology. Also used to bonding and sealing for connectors of electrical equipment and heat shrink mould parts. ",
      features: [
        "Excellent sealing and waterproof properties.",
        "  Anti-tracking, Anti-ageing.",
        "  Excellent self-sticking or adhesion ",
        "  Standard color : Red.",
        "Packed in Metalized covers for longer life",
        "Good ageing and weather resistance.",
        "Environmentally friendly in production, application and disposal."
      ],
      applications: [
        "Used to filling the spacing between cable cores for insulation. ",
        "Sealing and waterproof performances.",
        "To provide sealing and anti-tracking for power cable termination and joints for heat shrink and cold shrink technology.",
        "Suitable for used in 11KV, 25KV and 36KV joints and terminations."
      ],
      imgUrl: imgUrl.red_mastic_tape
    },
    {
      categoryId: "mastic_tapes",
      id: "stress_control_mastic",
      name: "Stress Control Mastic",
      description:
        "Stress control mastic provide stress relief of the electrical field in all MV cables joints and terminations for power cable applications. The stress control mastic with excellent stress controlling and self-sticking properties make it widely use in heat shrink and cold shrink technologies. Stress control mastic equilibrate the electrical field and reduce the electrical stress. Stress control mastic resistant to water and humidity, filling voids and irregular shapes.",
      features: [
        "Excellent stress controlling.",
        "  Good Dielectric constant.",
        "  Excellent adhesion ",
        "  Standard color : Yellow, Brown.",
        "Packed in Metalized covers for longer life",
        "Good ageing and weather resistance.",
        "Environmentally friendly in production, application and disposal."
      ],
      applications: [
        "Stress control mastic provide stress relief of the electrical field in all MV cables joints and terminations for power cable applications. ",
        "Stress control mastic resistant to water and humidity, filling voids and irregular shapes.",
        "The stress control mastic with excellent stress controlling and self-sticking properties make it widely use in heat shrink and cold shrink technologies.",
        "Suitable for used in 11KV, 25KV and 36KV joints and terminations."
      ],
      imgUrl: imgUrl.mastic_tape_stress_grading
    },
    {
      categoryId: "mastic_tapes",
      id: "Butyl_Sealants_for_roofing_and_cladding",
      name: "Butyl Sealants for roofing and cladding",
      description:
        "We are the manufacture for the preformed butyl tape, an integral part of an industrial roofing system. In a built-up roof, double-sided butyl is used on the end laps of the liner panel and a single faced Polyband strip along the side laps. The top sheet, or weather sheet, is sealed along the end laps and side laps with a double-sided butyl tape. Composite panel roofs are sealed with a double-sided butyl tape along both end laps and side laps. Foam fillers at the ridge and eaves are fixed in place with a thinner tape and this is also used in the production of rooflights. Butyl tape has further applications around rooflights, gutters and flashings. Some wall cladding designs also call for butyl tape to be applied along the side laps. Roof and wall membranes are also sealed with double-sided butyl.Also known as metal roofing tape, metal butyl sealant tape..",
      features: [
        "Weather and age resistant..",
        "  Temperature resistant from -10 C to 90 C",
        "  Excellent adhesion ",
        "  Resist moisture, vapour transmissions.",
        "Packed in Metalized covers for longer life",
        "Will retain tack and adhesion.",
        "Environmentally friendly in production, application and disposal."
      ],
      applications: [
        "Used in Metallic roofing. ",
        "Used in PEB buildings.",
        "Ducting and Ventilation,sealing lap joints",
        "Used in water tanks."
      ],
      imgUrl: imgUrl.mastic_tape_roofing
    },
    {
      categoryId: "mastic_tapes",
      id: "Butyl_tapes_for_vaccum_bagging_and_composites",
      name: "Butyl tapes for vaccum bagging and composites",
      description:
        "Sealing tape are developed for vacuum bagging operations. Mastic tapes has been formulated to outperform all tapes in its category. With aggressive initial tack, it will maintain an air-tight seal during the cure cycle, yet strip clean from the tool, with virtually no trace of residue. provides easy de-bagging and minimal clean-up between production cycles. exhibits these excellent usage and release characteristics from composite and metal tools, in both oven and autoclave applications. The tape . Economic butyl sealant tape, ideal for use in all those processes where the working temperature does not exceed 150°C. The butyl / sealant tape is typically for sealing the substrate to the vacuum bag. With a combination of synthetic rubber with inert fillers, plasticiser and tackifiers, the tape provides an excellent bond to variety of substrates and less likely to incur any leaks. The tackiness of the tape is formulated in such a way it provides a clean release once the composite is formed.",
      features: [
        "Known as vaccum bag sealant tapes",
        "  Available in custom sizes and length",
        "  Excellent adhesion ",
        "  Resist moisture, vapour transmissions.",
        "Air-tight seal ",
        "Will retain tack and adhesion.",
        "Environmentally friendly in production, application and disposal."
      ],
      applications: [
        "Vaccum bagging. ",
        "Butyl adhesive tape on wind turbine blades.",
        "Adhesive tapes for composites.",
        "Sealant tapes for vaccum moulding."
      ],
      imgUrl: imgUrl.mastic_tape_vaccum_bagging
    },
    {
      categoryId: "mastic_tapes",
      id: "Butyl_sealants_for_automotive_and_sound_dampening",
      name: "Butyl sealants for automotive and sound dampening",
      description:
        "We offer Butyl Based Sound Dampening Anti Vibration Pads. Apart from being strong adhesive tapes, butyl rubber is most effective in drowning out sounds and vibrations. It has an excellent noise and vibration dampener . Owing to this property all major Automobile companies, Refrigeration & Air conditioners manufacturers worldwide are using butyl pads for vibration & noise reduction. We produce butyl anti vibration pads in various sizes & thickness as per the specific requirements of our customers. Automotive uses include sealing around the frames of sunroofs, in the dust panels inside car doors, around head and rear lights in panel beating shops and as well as mounting tracking devices in hidden compartments inside vehicles. It has great sound deadening applications for the automotive industry.",
      features: [
        "Fine finish,durability",
        "  Available in custom sizes and length",
        "Good sound dampening ",
        "Will retain tack and adhesion.",
        "Environmentally friendly in production, application and disposal."
      ],
      applications: [
        "Automobiles for sound dampening. ",
        "Doors, fuel tanks and windows of automotives."
      ],
      imgUrl: imgUrl.mastic_tape_automotive
    },
    {
      categoryId: "mastic_tapes",
      id: "silicone_self_fusing_sealant_tapes",
      name: "Silicone Self fusing sealant tapes",
      description:
        "Silicone tapes are produced from specially formulated silicone rubber.  They bond irreversibly to provide an insulative barrier that is resistant to moisture, oxygen, ozone and corona over a wide temperature range (-65°F up to +500°F).  Our products provide superior electrical insulation with a dielectric strength of 300  VPM minimum at 356°F/180°C.",
      features: [
        "High temperature and good weather resistance",
        "  Available in custom sizes and length",
        "  Self fusing ",
        "Good electrical insulation properties"
      ],
      applications: [
        "Aerospace. ",
        "Wire harness.",
        "Wrapped around cables and electrical lines, Automotive applications"
      ],
      imgUrl: imgUrl.mastic_tape_sealant
    }
  ];


  const contextHook = useState(productList);
  return (
    <ProductListContext.Provider value={contextHook}>
      {props.children}
    </ProductListContext.Provider>
  );
}

export default ProductListContextProvider;
