import React, { useContext } from "react";
import { ContactUsContext } from "../../contexts/ContactUsContext";
import { Button, Box, Text } from "stormbreaker";
import Truncate from "../Truncate";
import Slider from "../Slider";
import styled from "@emotion/styled";
import MobileProductCard from './MobilePage'

const SliderImg = styled("img")`
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
`;

const ProductCard = ({ name, description, imgUrl, id }) => {
  const [,setOpen] = useContext(ContactUsContext);
  return (
    <Box display="inline-flex">
      <Box mr={5}>
        <Box
          bg="mono50"
          borderRadius={10}
          height={200}
          width={250}
          style={{
            boxShadow: "0 8px 20px rgba(143,168,191,.2)"
          }}
        >
          <Slider>
            {imgUrl.map((url, i) => (
              <SliderImg src={url} alt={name} key={i} />
            ))}
          </Slider>
        </Box>
      </Box>
      <Box>
        <Text variant="h4" color="mono900" fontWeight="bold">
          {name}
        </Text>
        <Box mt={1} />
        <Truncate line={3} color="mono400" variant="body1">
          {description}
        </Truncate>
        <Box mt={6} />
        <Box display="flex">
          <Button href={`/products/details/${id}`}>VIEW DETAILS</Button>
          <Box mr={4} />
          <Button onClick={() => setOpen(true)}>get quotes</Button>
        </Box>
      </Box>
    </Box>
  );
};

ProductCard.Mobile = MobileProductCard

export default ProductCard;
