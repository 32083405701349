import React from "react";
import PropTypes from "prop-types";
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { Button, Container, Grid, Text, Box } from "stormbreaker";

const SlideImg = styled(motion.div)`
  background-image: url(${props => props.backgroundImage});
  height: 500px;
  width: 100%;
  background-size: ${props => props.backgroundSize};
  background-position: ${props => props.backgroundPosition};
  background-repeat: ${props => props.backgroundRepeat};
  background-color: ${props => props.theme.colors[ props.bg ]};
  filter: contrast(120%);
`

const Slide = props => {
  return (
    <>
      <Box mt={5} />
      <Container alignItems="center">
        <Grid span={6}>
          <Box
            // width={500}
            display="flex"
            flexDirection="column"
            alignItems="left"
            ml={`calc((100vw - 1200px)/2)`}
          >
            <Box>
              <Text variant="h1" textTransform="uppercase" letterSpacing="wide" color="mono900" fontWeight="light">
                {props.title}
              </Text>
              <Box pt={4} />
              <Text variant="body3" color="mono900" fontWeight="light" >
                {props.label}
              </Text>
              <Box pt={8} />
              {/* <Box display="flex">
                <Button href="/products/silicone_tubings" size="large" appearance="primary">
                  view products
                </Button>
              </Box> */}
            </Box>
          </Box>
        </Grid>

        <Grid span={6}>
          <Box display="flex" justifyContent="flex-end" >
            <Box height={500}  width="90%" style={{
              overflow: 'hidden'
            }}>
              <SlideImg
                animate={{ scale: 1.2 }}
                transition={{
                  yoyo: Infinity,
                  duration: 10,
                  linear: "linear"
                }}
                bg="mono100"
                backgroundSize="cover"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundImage={props.img}
              />
            </Box>
          </Box>
        </Grid>

      </Container>
    </>
  );
};

Slide.defaultProps = {
  label: "Label",
  title: "Title"
};

Slide.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string
};

export default Slide;
