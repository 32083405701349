import React, { useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { ProductListContext } from "../../contexts/ProductListContext";
import ProductDetails from "../../Components/ProductDetails";
import { Helmet } from "react-helmet";
import ContactUs from '../../Components/ContactUs';

function DetailsPage() {
  const { id } = useParams();
  const history = useHistory();
  const [productList] = useContext(ProductListContext);
  const productArr = productList.filter(item => id === item.id);
  let product = productArr[0];
  useEffect(() => {
    if (!productArr.length) {
      history.push("/");
    } else {
      product = productArr[0];
    }
  }, [productArr]);

  return (
    <>
      {product && (
        <>
          <Helmet>
            <title>{product.name}</title>
            <link rel="canonical" href={`https://centroidpolymer.com/products/details/${product.id}`} />
          </Helmet>
          <ContactUs />
          <ProductDetails product={product} />
        </>
      )}
    </>
  );
}

export default DetailsPage;
