import { theme } from "stormbreaker";

export const CentroidTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    primary50: "#f9e5e5",
    primary100: "#eeb2b2",
    primary200: "#e47f7f",
    primary300: "#d94c4c",
    primary400: "#d33232",
    primary500: "#c90000",
    primary: "#c90000",
    primary600: "#b40000",
    primary700: "#a00000",
    primary800: "#8c0000",
    primary900: "#780000",
    accent50: "#E1F5FE",
    accent100: "#B3E5FC",
    accent200: "#81D4FA",
    accent300: "#4FC3F7",
    accent400: "#29B6F6",
    accent500: "#03A9F4",
    accent: "#03A9F4",
    accent600: "#039BE5",
    accent700: "#0288D1",
    accent800: "#0277BD",
    accent900: "#01579B"
  }
};
