import React from "react";
import { Box, Button, Text } from "stormbreaker";

function CategoryCard(props) {
  return (
    <Box
      style={{
        boxShadow: "0 0 20px 20px rgba(0,0,0,0.07)"
      }}
      bg="mono50"
      borderRadius={12}
      mb={16}
    >
      <Box
        borderColor="mono200"
        borderTopLeftRadius={12}
        borderTopRightRadius={12}
        bg="mono200"
        height={200}
        style={{
          backgroundImage: `url(${props.imgUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      />
      <Box pt={5} />
      <Box px={4}>
        <Text variant="body3" fontWeight="semibold" color="mono800">
          {props.name}
        </Text>
        <Box pt={3} />
        <Text variant="body2" color="mono400" fontWeight="normal">
          {props.description}
        </Text>
        <Box pt={12} />
        <Button
          href={`/products/${props.categoryId}`}
          size="large"
          appearance="primary"
          outline
          block
        >
          view products
        </Button>
        <Box pt={4} />
      </Box>
    </Box>
  );
}

export default CategoryCard;
