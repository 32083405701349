export const imgUrl = {
  silicone_Braided_Hose: [ 'https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587923042/SILICONE_HOSES/silicone%20braided%20hoses/Braided-hose-Transparent-Food-Grade-Silicone-Flexible_qzkony.jpg', 'https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587923042/SILICONE_HOSES/silicone%20braided%20hoses/braided-high-quality-FDA-certificate-raw_material-fda-food-fluid-juice-pressure-high-temperature-sterlizable_lzg74g.jpg', 
'https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587923041/SILICONE_HOSES/silicone%20braided%20hoses/silicone-braided-high-pressure-hoses-heater-200C-temperature-steam-fda-bio-best-quality-platinum-food-fluid-drugs_f6r0mj.jpg' ],

  silicone_heater_hose: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587924391/SILICONE_HOSES/silicone%20heater%20hose/silicone-automotive-flexible-heater-hose-heat-resistant_jjuk7p.jpg', 'https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587924390/SILICONE_HOSES/silicone%20heater%20hose/silicone-heater-hose-hose-rubber-supply-heater-hose-l-c938676ac3a5f739_j05e8d.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587924390/SILICONE_HOSES/silicone%20heater%20hose/silicone-rubber-Braided-automotive-Flexible-heater-Gas-Hose-fluid-liquidsilicone-rubber-automotive-high-quality_qxzyeo.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587924389/SILICONE_HOSES/silicone%20heater%20hose/silicone-heater-hose-high-temperature-high-tear-oem-rubber-braided-red-blue_edacmh.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1594741707/SILICONE_HOSES/silicone%20heater%20hose/silicone_hose_gajqdr.jpg'],
  silicone_profiles:['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587924896/SILICONE_EXTRUSIONS/silicone%20profiles/silicone_profiles_silicone-rubber-high-temperature-square-rectangle-profiles-colour-fda-food-grade-temperature-durable-best-price_fkvbld.jpg', 'https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587924896/SILICONE_EXTRUSIONS/silicone%20profiles/silicone_profiles_silicone-custom-profiles-gaskets-g_type-j_type-h_type-u_type-e_type-p_type-fda-food-high-temperature-quality_1_g3nq7o.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587924895/SILICONE_EXTRUSIONS/silicone%20profiles/silicone_profiles_silicone_extruded_profiles_db1jop.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587924893/SILICONE_EXTRUSIONS/silicone%20profiles/silicone_profiles_silicone-strips_square_red_white-high_quality_FDA-temperature-bio-india_osvxp7.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587924893/SILICONE_EXTRUSIONS/silicone%20profiles/silicone_profiles_silicone-bakery-oven-gasket-profile-sealing-door-FDA-food-high-quality-best-price_dyrhbk.jpg'],

  silicone_conductor_cover: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587925103/SILICONE_EXTRUSIONS/Silicone%20overhead%20line%20cover/Silicone_overhead_line_cover_Substation-Conductor-Silicone-Rubber-Overhead-Line-Cover_eitzyb.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587925103/SILICONE_EXTRUSIONS/Silicone%20overhead%20line%20cover/Silicone_overhead_line_cover_overhead-line-silicone-conductor-cover_y7bjs5.png','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587925102/SILICONE_EXTRUSIONS/Silicone%20overhead%20line%20cover/Silicone_overhead_line_cover_overhead_line_cover_tbxcpo.jpg'], 

  silicone_sponge: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587925372/SILICONE_EXTRUSIONS/silicone%20sponge%20/silicone_sponge_Silicones_Closed_Cell_Silicone_Sponge_Cord_Extrusions-autoclavable-FDA-round-square-any_edfwj7.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587925372/SILICONE_EXTRUSIONS/silicone%20sponge%20/silicone_sponge_Extruded-silicone-door-best-gasket_quality_rcdvbc.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587925372/SILICONE_EXTRUSIONS/silicone%20sponge%20/silicone_sponge_silicone_sponge_foam_led_food_grade_door-oven-seals-medical_zsmyug.jpg'],

  black_mastic_tape: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1594730889/MASTIC_TAPES/Black%20Mastic/Black_Mastic_Sealant-Mastic-Butyl-Tape-butyl_pe3qaw.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1592071922/MASTIC_TAPES/Black%20Mastic/Black_Mastic_butyl-tapes-sealants-jointing-antitracking-best-good_edvmhq.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1592071922/MASTIC_TAPES/Black%20Mastic/Black_Mastic_Butyl_Black_mastic_cgkjm5.jpg'],

  mastic_tape_roofing: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1592072059/MASTIC_TAPES/Butyl%20Mastic%20tapes%20for%20roofing%20and%20construction/Butyl_Mastic_tapes_for_roofing_and_construction_butyl-roofing-construction-sealing_bwhxpt.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1592072054/MASTIC_TAPES/Butyl%20Mastic%20tapes%20for%20roofing%20and%20construction/Butyl_Mastic_tapes_for_roofing_and_construction_Butyl-Mastic-roofing-sealing-construction-fabrication_pvt84n.jpg'],

  mastic_tape_automotive: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1594737227/MASTIC_TAPES/Butyl%20Sealant%20tapes%20for%20sound%20deadening/Butyl_Sealant_tapes_for_sound_deadening_butyl_tapes_automotive_oc7twl.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1594737227/MASTIC_TAPES/Butyl%20Sealant%20tapes%20for%20sound%20deadening/Butyl_Sealant_tapes_for_sound_deadening_black_butyl_mastic_sealant_waterproofing_dogqe5.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1594737227/MASTIC_TAPES/Butyl%20Sealant%20tapes%20for%20sound%20deadening/Butyl_Sealant_tapes_for_sound_deadening_anti_vibration_black_butyl_centroid_adhesive_sound_deadening_alamjb.jpg'],

  mastic_tape_vaccum_bagging: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587926913/MASTIC_TAPES/Butyl%20sealant%20tapes%20for%20vacuum/Butyl_sealant_tapes_for_vacuum_windmill_vaccum_baggaging_butyl_tape_adhesive_sealant_cvdbqd.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1592072478/MASTIC_TAPES/Butyl%20sealant%20tapes%20for%20vacuum/Butyl_sealant_tapes_for_vacuum_butyl_sealants_vaccum_bagging_composite_high_low_temperature_gycwrr.jpg'],

  red_mastic_tape: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1594737049/MASTIC_TAPES/Red%20mastic/Red_mastic_Mastic_srx1zh.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1594737049/MASTIC_TAPES/Red%20mastic/Red_mastic_red_mastic_tape_application_best_anti_tracking_property_certified_lgwzij.jpg'],

  mastic_tape_sealant: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1594736772/MASTIC_TAPES/Silicone%20Self%20fusing%20Sealant%20tapes/Silicone_Self_fusing_Sealant_tapes_grey-1x36-er-silicone-tape-no-packaging_1024x1024_mabhok.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1594736772/MASTIC_TAPES/Silicone%20Self%20fusing%20Sealant%20tapes/Silicone_Self_fusing_Sealant_tapes_self-fusing-tpe-best-silicone_cbhlfw.jpg'],

  mastic_tape_stress_grading: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1594737120/MASTIC_TAPES/Stress%20grading%20mastics%20for%20cable%20jointing/Stress_grading_mastics_for_cable_jointing_stress-grade-mastic-tape-cable-jointing_cjfzdn.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1594737120/MASTIC_TAPES/Stress%20grading%20mastics%20for%20cable%20jointing/stress-control-mastic-tape-yellow-grey-for-cable_xawxnl.jpg'],

  silicone_bellows_expansion: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587927832/SILICON_MOLDED/silicone%20bellows%20expansion/silicone_bellows_expansion_right-angle-silicone-rubber-bushing-boot-straight-boot-bellow-electrical-RMU-cable-kit-best-high-insulation-WEATHERPROOF-INDIA-best-price.jpg_wuvn6x.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587927831/SILICON_MOLDED/silicone%20bellows%20expansion/silicone_bellows_expansion_silicone-rubber-bushing-boot-straight-boot-bellow-electrical-RMU-cable-kit-best-high-insulation-WEATHERPROOF-INDIA_hyciaf.jpg'],

  silicone_custom_moldings: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587927946/SILICON_MOLDED/Silicone%20custom%20mouldings/Silicone_custom_mouldings_Solid_-Silicone-Molded-Products-gaskets-seals-plugs-caps-joined-inflatable_oqvr0v.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587927945/SILICON_MOLDED/Silicone%20custom%20mouldings/Silicone_custom_mouldings_silicone_airlock_dryer_FDA_India_custom_electronic_processing_food_inflatable__z4i3pj.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587927944/SILICON_MOLDED/Silicone%20custom%20mouldings/Silicone_custom_mouldings_custom-silicone-rubber-mouldings-ul-food_grade_hl0fko.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587927944/SILICON_MOLDED/Silicone%20custom%20mouldings/Silicone_custom_mouldings_FDA-inflatable-seals-gaskets_pharma_sterlizable_FBD-autoclave-furnace-door-air-nuclear-mixer_podiks.jpg'],

  silicone_orings: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928054/SILICON_MOLDED/silicone%20orings/silicone_orings_silicone-gaskets-mouldings-orings-led-sealing-high_temperature-colour-vmq_flni8s.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928054/SILICON_MOLDED/silicone%20orings/silicone_orings_silicone-o-ring-top-quality-best-rubber_ygjhh9.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928054/SILICON_MOLDED/silicone%20orings/silicone_orings_flurosilicone-o-ring-silicone_-food_grade-medical-pharma-sealing_zx91xp.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928053/SILICON_MOLDED/silicone%20orings/silicone_orings_silicone-oring-seals-food_grade-medical_grade-high-quality-best-sealing_zwccdl.jpg'],

  silicone_overmolding: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928459/SILICON_MOLDED/silicone%20overmoulding/silicone_overmoulding_silicone_handles_kf3xva.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928458/SILICON_MOLDED/silicone%20overmoulding/silicone_overmoulding_silicone-rubber-medical-handles-resistant-sterlizable-FDA-bio-best-weatherproof-high-quality_rldtpj.jpg'],

  silicone_triclover_gaskets: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928534/SILICON_MOLDED/Silicone%20triclover%20gaskets/Silicone_triclover_gaskets_tri-clover-gasket-sanitary-food_grade-pharma-sealing-best-india_o85xo4.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928534/SILICON_MOLDED/Silicone%20triclover%20gaskets/Silicone_triclover_gaskets_tri-clover-gasket-sanitary-food_grade-pharma-sealing_lyshkl.jpg'],

  ptfe_molded_cylinders: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928823/PTFE_TEFLON/molded%20ptfe%20cylinders/molded_ptfe_cylinders_High-Temperature-Resistant-Virgin-Teflon-PTFE_bms0ur.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928823/PTFE_TEFLON/molded%20ptfe%20cylinders/molded_ptfe_cylinders_PTFE-BUSH-cylinder-custom-length-size-Food_grade-machined_tam3vz.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928822/PTFE_TEFLON/molded%20ptfe%20cylinders/molded_ptfe_cylinders_ptfe-filled-grade-custom-best-diameter-machined-bush_packgg.jpg'],

  ptfe_machined_parts: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928931/PTFE_TEFLON/PTFE%20machined%20parts/PTFE_machined_parts_mg_6482_oc2bdx.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928931/PTFE_TEFLON/PTFE%20machined%20parts/PTFE_machined_parts_ptfe-machined-products-500x500_ozhygt.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928930/PTFE_TEFLON/PTFE%20machined%20parts/PTFE_machined_parts_PTFE-machined-custom-best-food_grade-filled-valve-chemical_bdyyne.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928929/PTFE_TEFLON/PTFE%20machined%20parts/PTFE_machined_parts_PTFE-teflon-custom-machined-best-part-food-grade-chemical_valve-fluid_lnfpub.jpg'],

  ptfe_orings: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928964/PTFE_TEFLON/PTFe%20o%20rings%20and%20seals/PTFe_o_rings_and_seals_PTFE-orings-triclover-gaskets-food_grade-virgin-filled-custom_ufybnd.jpg'],

  ptfe_skived_strips: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928990/PTFE_TEFLON/PTFE%20skived%20strips/PTFE_skived_strips_PTFE-strips-custom-cut_ntx9lr.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587928986/PTFE_TEFLON/PTFE%20skived%20strips/PTFE_skived_strips_PTFE_teflon-skived-strip-custom-sealing-chemical_zjsg5a.jpg'],

  ptfe_valved_seats: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929036/PTFE_TEFLON/PTFE%20valve%20seats/PTFE_valve_seats_Teflon-PTFE-Ball-Valve-Seats-56475_olhp8m.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929036/PTFE_TEFLON/PTFE%20valve%20seats/PTFE_valve_seats_1534412868_ktkhbh.jpg'],

  ptfe_wear_rings: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929067/PTFE_TEFLON/PTFE%20wear%20rings%2C%20piston%20seals/PTFE_wear_rings_piston_seals_Simmerring_RMS_XP_41_givdhm.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929067/PTFE_TEFLON/PTFE%20wear%20rings%2C%20piston%20seals/PTFE_wear_rings_piston_seals_StripBearings_wm0yiz.jpg'],

  tubing_platinum_grade: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929214/SILICONE_TUBINGS/Platinum%20grade%20silicone%20tubings/platinum-cured-silicone-tubing-regulatory-non-regulatory-medical-high-quality_lgq03b.png','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929214/SILICONE_TUBINGS/Platinum%20grade%20silicone%20tubings/transparent-silicone-tubing-medical-pharmaceutical-best-india-all_world-best_q1nwcq.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929213/SILICONE_TUBINGS/Platinum%20grade%20silicone%20tubings/transparent-silicone-tubing-indiamedical-pharmaceutical-best-india-manufacturer_sezq6w.jpg'],

  tubing_food_grade: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929220/SILICONE_TUBINGS/Food%20grade%20silicone%20rubber%20tubing/platinum-food-biotech-pharmaceutical-Silicone-Rubber-Tubing-best-white-transparent-tubing-sterlizable-autoclave-machine-_ykno2r.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929220/SILICONE_TUBINGS/Food%20grade%20silicone%20rubber%20tubing/colour-custom-sleeve-rubber-5mm-4mm-1mm-2mm-BLUE-Red-SILICONE-VACUUM-TURBO-DUMP-VALVE-tube-FDA-electrical_qurcij.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929219/SILICONE_TUBINGS/Food%20grade%20silicone%20rubber%20tubing/peroxide-pumps-peristaltic-silicone-tube-white-red-transparent-medical-fda-electrical-harness-good-quality-high-temperature_fcszwj.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929219/SILICONE_TUBINGS/Food%20grade%20silicone%20rubber%20tubing/any-blue-silicone-rubber-extruded-best-quality-high-temperature-india-export-FDA-fda-medical-grade-any-size-custom_jqtpuw.jpg'],

  tubing_corona_treater: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929208/SILICONE_TUBINGS/Silicone%20Corona%20treater%20sleeves/silicone-corona-treater-sleeve-best-quality_kyldhb.jpg', 'https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929207/SILICONE_TUBINGS/Silicone%20Corona%20treater%20sleeves/corona-treater-sleeve-roll-dielectic-plasma-insulation_lbsutj.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929207/SILICONE_TUBINGS/Silicone%20Corona%20treater%20sleeves/roller-traction-shrink-tunnel-packaging_jvld6v.jpg'],

  tubing_medical_grade: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929228/SILICONE_TUBINGS/Medical%20grade%20silicone%20tubing/Medical_Grade_Silicone_Tubing_best_india_fqgxxa.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929227/SILICONE_TUBINGS/Medical%20grade%20silicone%20tubing/medical-grade-silicone-tubing_shn5b2.jpg'],

  tubing_silicone_sleeves: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929205/SILICONE_TUBINGS/silicone%20sleeves%20or%20thin%20walled%20sleevings/silicone-sleeve-red-fire-resistant-high-temperature-best-india_o89xfn.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929204/SILICONE_TUBINGS/silicone%20sleeves%20or%20thin%20walled%20sleevings/silicone-sleeve-thin_walled-red-black-transparent-fire-resistant_keejgk.jpg','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929204/SILICONE_TUBINGS/silicone%20sleeves%20or%20thin%20walled%20sleevings/silicone-sleeve-fire-resistant-thin-walled-any_colour_gtusf1.jpg'],

  tubing_peristaltic_pump: ['https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929028/SILICONE_TUBINGS/Custom%20peristaltic%20pump%20tubing/peristaltic-pump-tubing-india-manufacturer_zovkse.png','https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587929027/SILICONE_TUBINGS/Custom%20peristaltic%20pump%20tubing/custom-peristaltic-pump-tubing-silicone_yodlfq.jpg'],

  silicone_hoses: 'https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587930361/SILICONE_HOSES/silicone_braided_mgd1gh.jpg',

  site_img : 'https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587931461/Home/location-clean-manufacturing-facility_f7eoee.jpg',

  location_img: 'https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587931657/Home/silicone_rubber-mastic_tapes_manufacturer_mh5hed.jpg',

  silicone_rubber_extrusion: 'https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/v1587931923/Home/silicone_rubber_extrusion_j2q4dt.jpg',

  silicone_brading_machine: 'https://res.cloudinary.com/centroidpolymer/image/upload/q_auto,f_auto/w_400,h_400/v1587931457/Home/slider-braided-hose-silicone-rubber_r99fib.jpg'

}
