import React, { useContext } from 'react';
import { ContactUsContext } from '../../contexts/ContactUsContext';
import { Container, Box, Grid, Text, Button } from 'stormbreaker';
import styled from '@emotion/styled';
import Slider from '../Slider';

const StyledImg = styled('img')`
	height: 200px;
	border-radius: 10px;
	object-fit: cover;
`;

function MobileProductDetails({ product }) {
	const { name, description, imgUrl, applications, features } = product;
	const [, setOpen] = useContext(ContactUsContext);
	return (
		<>
			<Box>
				<Box mt={10} />
				<Container px={4}>
					<Grid span={12}>
						<Box height={120}>
							<Slider arrowSize={8}>
								{imgUrl.map((src) => (
									<StyledImg src={src} alt={name} />
								))}
							</Slider>
						</Box>
					</Grid>
          <Box height={220} />
					<Grid span={12}>
						<Text variant='h4' fontWeight='bold' color='mono900'>
							{name}
						</Text>
						<Box mt={4} />
						<Text variant='body2' color='mono' fontWeight='normal' lineHeight='copy'>
							{description}
						</Text>
						<Box mt={6} />
						<Button size='medium' appearance='primary' onClick={() => setOpen(true)}>
							Get quotes
						</Button>
					</Grid>
				</Container>
				<Box mt={10} />
				<Container px={4 }>
					{features ? (
						<Grid span={12}>
							<Box style={{ overflow: 'hidden' }}>
								<Box flex={1} py={4}>
									<Text variant='body2' color='mono900' fontWeight="medium" letterSpacing='wide'>
										FEATURES
									</Text>
								</Box>
								<Box py={1}>
									{features.map((list, index) => (
										<Box py={1} bg='white' display='flex'>
                      <Box>
                      <Text variant='body1' fontWeight='bold' color='mono900'>
													{index + 1}.
												</Text>
                      </Box>
											<Text variant='body2' color='mono800' pl={2}>
												{list}
											</Text>
										</Box>
									))}
								</Box>
							</Box>
						</Grid>
					) : (
						<Box />
					)}
          <Box mt={5} />
					{applications ? (
						<Grid span={12}>
							<Box style={{ overflow: 'hidden' }}>
              <Box flex={1} py={4}>
									<Text variant='body2' color='mono900' fontWeight="medium" letterSpacing='wide'>
										APPLICATIONS
									</Text>
								</Box>
                <Box py={1}>
									{applications.map((list, index) => (
										<Box py={1} bg='white' display='flex'>
                      <Box>
                      <Text variant='body1' fontWeight='bold' color='mono900'>
													{index + 1}.
												</Text>
                      </Box>
											<Text variant='body2' color='mono800' pl={2}>
												{list}
											</Text>
										</Box>
									))}
								</Box>
							</Box>
						</Grid>
					) : (
						<Box />
					)}
				</Container>
				<Box mt={20} />
			</Box>
		</>
	);
}

export default MobileProductDetails;
