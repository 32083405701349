import React from 'react';
import { Box, Button, Text } from 'stormbreaker';
import Truncate from '../Truncate'

function CategoryCard(props) {
	return (
		<Box px={4} mb={6} display='flex'>
      <Box>
    	<Box
				borderColor='mono200'
				borderRadius={4}
				bg='mono200'
				height={132}
				width={132}
				style={{
					backgroundImage: `url(${props.imgUrl})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			/>
      </Box>
			<Box pl={3}>
				<Text variant='body2' fontWeight='semibold' color='mono800'>
					{props.name}
				</Text>
				<Box pt={1} />
				<Truncate line={3} variant='body1' color='mono400' fontWeight='normal' >
					{props.description}
				</Truncate>
				<Box pt={3} />
				<Button href={`/products/${props.categoryId}`} size='small' appearance='primary'>
					view products
				</Button>
			</Box>
		</Box>
	);
}

export default CategoryCard;
