import React from "react";
import { Text } from "stormbreaker";
import styled from "@emotion/styled";

const StyledText = styled(Text)`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: ${props => (props.line ? props.line : 1)};
  -webkit-box-orient: vertical;
`;

function Truncate(props) {
  return <StyledText {...props}>{props.children}</StyledText>;
}

export default Truncate;
