import React from "react";
import { Carousel, Slide } from "../../Components/Carousel";
import Header from "../../Components/Header";
import SectionTitle from "../../UIcomponents/SectionTitle";
import Footer from '../../Components/Footer'
import Banner from '../../Components/Banner'
import { Box, Container, Grid } from "stormbreaker";
import ProductCategory from '../../Components/ProductCategory'
import TopNotch from '../../Components/TopNotch'
import ContactUs from '../../Components/ContactUs'
import LocatedAt from '../../Components/LocatedAt'
import MarketingAreas from '../../Components/MarketingAreas';
import {imgUrl} from '../../images'

const HomePage = () => {
  return (
    <div>
      <ContactUs />
      <Header email="info@centroidpolymer.com" />
      <Carousel>
      <Slide
          img={imgUrl.site_img}
          title="Why centroid polymer?"
          label="We are manufacturers of Gaskets, Seals, Rubber mouldings,Silicone Extrusions,Hoses, Rubber mastic tapes ,PTFE mouldings and a leading provider of bespoke industrial solutions.We process rubber and engineering plastics using the processing technologies Moulding,extrusion and handlayup.
          Everything we do at Centroid polymer technologies is governed by a strong set of core values."
        />

        <Slide
          img={imgUrl.silicone_rubber_extrusion}
          label="Silicone rubber known as polysiloxanes or polydimethylsiloxanes is a generally non-reactive,
          stable, resistant, autoclavable elastomer to extreme environments and temperatures from −55 to
          300 °C (−67 to 572 °F).Inert with almost all chemicals susceptible to UV, Ozone and used in Aerospace, Automotive,
          HVAC ,Life science, food processing and beverage, Medical, Machinery, Pharma ,LED lighting, Rail and mass transit."
          title="Why Silicone rubber ?"
        />


        <Slide
          img={imgUrl.mastic_tape_vaccum_bagging[0]}
          title="WHY Butyl Mastic tapes?"
          label="Butyl mastic tapes are butyl rubber based self-adhesive tapes with a high tack and self fusing
          property and covers a wide range of temperatures (both high and low temperature), provides
          weatherproofing, good sealing property."
        />

        <Slide
          title="WHY PTFE / Teflon?"
          label="Polytetrafluoroethylene (PTFE) is a synthetic fluoropolymer of tetrafluoroethylene. Also known as
          Teflon, it is resistant to high temperature and chemicals, best known for low friction, hydrophobic
          and electrical insulation."
          img={imgUrl.ptfe_machined_parts[3]}
        />
      </Carousel>
      <Box mt={10} />
      <TopNotch />
      <Box mt={15} />
      <Container maxWidth={1200}>
        <Grid>
          <SectionTitle title="Our featured products" />
        </Grid>
      </Container>
      <Box mt={15} />
      <ProductCategory />
      <Box mt={10} />
      <Banner />
      <Box mt={10} />
      <Container maxWidth={1200}>
        <Grid>
          <SectionTitle title="ACTIVE AREAS" />
        </Grid>
      </Container>
      <Box mt={15} />
      <MarketingAreas />
      <Box mt={10} />
      <Container maxWidth={1200}>
        <Grid>
          <SectionTitle title="WE ARE LOCATED AT" />
        </Grid>
      </Container>
      <Box mt={15} />
      <LocatedAt />
      <Box mt={20} />
      <Footer />
    </div>
  );
};
export default HomePage;
