import React, { useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { ProductListContext } from "../../../contexts/ProductListContext";
import ProductDetails from "../../../Components/ProductDetails";
import { Helmet } from "react-helmet";
import Footer from '../../../Components/Footer'
import Header from '../../../Components/Header'
// import ContactUs from '../../Components/ContactUs';

function MobileDetailsPage() {
  const { id } = useParams();
  const history = useHistory();
  const [productList] = useContext(ProductListContext);
  const productArr = productList.filter(item => id === item.id);
  let product = productArr[0];

  useEffect(() => {
    if (!productArr.length) {
      history.push("/");
    } else {
      product = productArr[0];
    }
  }, [productArr]);

  return (
    <>
      {product && (
        <>
          <Helmet>
            <title>Best quality {product.name}</title>
            <meta name="title" content={product.name}></meta>
            <link rel="canonical" href={`https://centroidpolymer.com/products/details/${product.id}`} />
          </Helmet>
          <Header.Mobile />
          {/* <ContactUs /> */}
          <ProductDetails.Mobile product={product} />
          <Footer.Mobile />
        </>
      )}
    </>
  );
}

export default MobileDetailsPage;
