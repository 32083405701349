import React from "react";
import { AppRoutes } from "./Routes";
import ProductCategoryContextProvider from "./contexts/ProductCategoryContext";
import ProductListContextProvider from "./contexts/ProductListContext";
import ContactUsContextProvider from "./contexts/ContactUsContext";
import { ThemeProvider, Global, globalStyles } from "stormbreaker";
import { CentroidTheme } from "./theme";

function App() {
  return (
    <ThemeProvider theme={CentroidTheme}>
      <Global styles={globalStyles} />
      <ContactUsContextProvider>
      <ProductCategoryContextProvider>
        <ProductListContextProvider>{AppRoutes}</ProductListContextProvider>
      </ProductCategoryContextProvider>
      </ContactUsContextProvider>
    </ThemeProvider>
  );
}

export default App;
