import React from "react";
import { Box, Container, Grid, Text } from "stormbreaker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { faThumbsUp, faHandPeace, faDraftingCompass } from "@fortawesome/free-solid-svg-icons";

import TopNotchMobile from './TopNotchMobile'

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 32px;
  color: ${props => (props.color ? props.theme.colors[props.color] : "")};
`;

function TopNotch() {
  return (
    <Container
      gap={22}
      maxWidth={1136}
      bg="white"
      p={8}
      borderRadius={15}
      style={{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 8px 20px"
      }}
    >
      <Grid span={4}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={68}
            width={68}
            borderRadius={68}
            bg="mono50"
          >
            <StyledIcon icon={faThumbsUp} color="success700" />
          </Box>
          <Box mt={5} />
          <Text variant="body3" fontWeight="semibold" align="center">
            100% quality
          </Text>
          <Box mt={1} />
          <Text
            variant="body2"
            fontWeight="light"
            color="mono400"
            align="center"
          >
            We use 100 % quality raw materials which are selected for the
            particular product and process.
          </Text>
        </Box>
      </Grid>
      <Grid span={4}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={68}
            width={68}
            borderRadius={68}
            bg="mono50"
          >
            <StyledIcon icon={faHandPeace} color="success700" />
          </Box>
          <Box mt={5} />
          <Text variant="body3" fontWeight="semibold" align="center">
            Fully Customisable
          </Text>
          <Box mt={1} />
          <Text
            variant="body2"
            fontWeight="light"
            color="mono400"
            align="center"
          >
            We have a variety of raw materials ranging from Aerospace material
            to Medical material which is fully customised for the particular
            Part.
          </Text>
        </Box>
      </Grid>
      <Grid span={4}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={68}
            width={68}
            borderRadius={68}
            bg="mono50"
          >
            <StyledIcon icon={faDraftingCompass} color="success700" />
          </Box>
          <Box mt={5} />
          <Text variant="body3" fontWeight="semibold" align="center">
            Design to Production
          </Text>
          <Box mt={1} />
          <Text
            variant="body2"
            fontWeight="light"
            color="mono400"
            align="center"
          >
            We have a dedicated design team to design, prototype and the
            production will be done only after customer approval.
          </Text>
        </Box>
      </Grid>
    </Container>
  );
}

TopNotch.Mobile = TopNotchMobile
export default TopNotch;
