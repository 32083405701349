import React, { createContext, useState } from "react";

export const ContactUsContext = createContext([{}, () => {}]);

function ContactUsContextProvider({ children }) {
  const hook = useState(false);
  return (
    <ContactUsContext.Provider value={hook}>
      {children}
    </ContactUsContext.Provider>
  );
}

export default ContactUsContextProvider;
