import React, { useContext } from 'react';
import { Text, Button, Box, Container, Grid } from 'stormbreaker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { ContactUsContext } from '../../contexts/ContactUsContext';
import { imgUrl } from '../../images';

function MobileLocatedAt() {
	const [, setOpen] = useContext(ContactUsContext);
	return (
		<>
			<Box
				bg='mono'
				backgroundImage={`url(${imgUrl.location_img})`}
				backgroundSize='cover'
				backgroundRepeat='no-repeat'
				backgroundPosition='center'
				height={200}
			></Box>

			<Box bg='mono50' px={4} py={4}>
				<Text color='mono300' mb={3} variant='body1' fontWeight='normal' letterSpacing='wide' lineHeight='copy'>
					PALAKKAD KERALA
				</Text>
				<Text variant='body3' fontWeight='semibold'>
					Centroid Polymer Technologies
				</Text>
				<Box border='3px solid' my={2} width={50} borderColor='primary300' />
				<Text color='mono600' variant='body1' fontWeight='medium' lineHeight='copy'>
					KINFRA Integrated Industrial & Textile Park 
				</Text>
				<Text color='mono600' variant='body1' fontWeight='medium' lineHeight='copy'>
				Plot no P32(4),P32(5) Kanjikode
				</Text>
				<Text color='mono600' variant='body1' fontWeight='medium' lineHeight='copy'>
					Palakkad - 678621, Kerala, India
				</Text>
				<Box display='flex' alignItems='center' mt={5}>
					<FontAwesomeIcon icon={faEnvelope} />
					<Text href='mailto:info@centroidpolymer.com' color='mono800' pl={2} variant='body2' fontWeight='medium'>
						info@centroidpolymer.com
					</Text>
				</Box>
				<Box display='flex' alignItems='center' mt={2}>
					<FontAwesomeIcon icon={faPhoneAlt} />
					<Text href='tel:9946231123' color='mono800' pl={2} variant='body2' fontWeight='medium'>
						9946231123, 9447159965
					</Text>
				</Box>
				<Box display='flex' alignItems='center' mt={2}>
					<FontAwesomeIcon icon={faMapMarkerAlt} />
					<Text
						href='https://g.page/silicone-butyl-ptfe?share'
						color='accent700'
						pl={2}
						variant='body2'
						fontWeight='medium'
						target='_blank'
					>
						Google map link
					</Text>
				</Box>
				<Box mt={9}>
					<Button onClick={() => setOpen(true)} outline size="small">
						CONTACT US
					</Button>
				</Box>
				<Box mt={15} />
				<Text variant='body1' color='mono300' fontWeight='light'>
					*We will deliver our products all over India
				</Text>
			</Box>
		</>
	);
}
export default MobileLocatedAt;
