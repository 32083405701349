import { useState } from 'react';

const useModal = () => {
    const [ isShowing, setIsShowing ] = useState(false)

    function toggle() {
        setIsShowing(!isShowing);
    }

    if(isShowing) {
        document.body.classList.add('o-hidden') 
    } else {
        document.body.classList.remove('o-hidden') 
    }
    
    return {
        isShowing,
        toggle
    }
};

export default useModal;