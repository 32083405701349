import React, { useContext } from "react";
import { ContactUsContext } from "../../contexts/ContactUsContext";
import { Button, Box, Text } from "stormbreaker";
import Truncate from "../Truncate";
import Slider from "../Slider";
import styled from "@emotion/styled";

const SliderImg = styled("img")`
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
`;

const MobileProductCard = ({ name, description, imgUrl, id }) => {
  const [,setOpen] = useContext(ContactUsContext);
  return (
    <Box px={4}>
      <Box>
        <Box
          bg="mono50"
          height={100}
          width="100%"
          style={{
            boxShadow: "0 8px 20px rgba(143,168,191,.2)"
          }}
        >
          <Slider settings={{autoplay: false}}>
            {imgUrl.map((url, i) => (
              <SliderImg src={url} alt={name} key={i} />
            ))}
          </Slider>
        </Box>
      </Box>
      <Box height={112} />
      <Text variant="body3"  color="mono900" fontWeight="medium">
          {name}
        </Text>
        <Box mt={1} />
        <Truncate line={3} color="mono400" variant="body1">
          {description}
        </Truncate>
        <Box mt={3} />
        <Box display="flex">
          <Button href={`/products/details/${id}`} size="small">VIEW DETAILS</Button>
          <Box mr={4} />
          <Button onClick={() => setOpen(true)} size="small">get quotes</Button>
        </Box>
    </Box>
  );
};

export default MobileProductCard;
