import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from '@emotion/styled'
import { faTimes, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { Button, Box, Container, Grid, Text, TextInput } from "stormbreaker";
import Logo from '../Logo'
import MobileHeader from './MobileHeader'

import { Link } from "react-router-dom";
import Modal from "../../lib/Modal";
import useModal from "../../lib/Modal/useModal";
import { axiosGetHelper, isEmpty } from "../../Helper/apiHelper";
import Catalogue from '../../assets/siliconetubes_siliconeprofiles_butyltapes_ptfe.pdf'

const HeaderIcon = styled(FontAwesomeIcon)`
  font-size: ${props => props.fontSize ? props.fontSize + 'px' : '28px'};
  color: ${props => props.theme.colors.mono};
  cursor: pointer;
  :hover{
    color: ${props => props.theme.colors.primary};
  }
`
// const ArrowIcon = styled(FontAwesomeIcon)`
//   font-size: 14px;
//   color: ${props => props.theme.colors.mono800};
// `

// const HoverBox = styled(Box)`
//   :hover {
//     border-radius: 4px;
//     background-color: ${props => props.theme.colors.mono100};
//     cursor: pointer;
//   }
// `

const Header = props => {
  const { isShowing, toggle } = useModal();
  const [ loading, setLoading ] = useState(false);
  const [ status, setStatus ] = useState("");
  const [ error, setError ] = useState({});
  let serialNumRef = useRef('')

  const getOrderStatusHandler = async (inputVal) => {
    try {
      const orderStatusResponse = await axiosGetHelper(
        "https://centroidrealtimedb-9140.restdb.io/rest/orderstatus"
      );

      if (!isEmpty(orderStatusResponse)) {
        setLoading(false);
        const result = orderStatusResponse.data.find(function (val) {
          return val.order_id === inputVal;
        });
        if (result) setStatus(result);
        else {
          setStatus({ ...result, 'order_status': "No result found" });
        }
      }
    } catch (e) {
      console.log("api error ", e);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (serialNumRef.current.value) {
      setLoading(true);
      getOrderStatusHandler(serialNumRef.current.value);
    } else {
      setError({
         "message": "Please enter valid serial number"
      });
    }
  }

  function onCloseModal() {
    toggle();
    setStatus("");
  }

  return (
    <div>
      <Box
        backgroundRepeat="no-repeat"
        // bg="mono50"
        // backgroundSize="contain"
        borderBottom="1px solid"
        borderColor="mono50"
        py={2}
      >
        <Container maxWidth={1200}>
          <Grid span={4}>
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <Link to={'/'}>
                <Logo height={60} />
              </Link>
            </Box>
          </Grid>
          {/* <Grid span={4}>
            <Box height={52} display="inline-flex" alignItems="center"> */}
          {/* <HeaderIcon icon={faFacebook} />
            <HeaderIcon icon={faTwitter} />
            <HeaderIcon icon={faYoutube} />
            <HeaderIcon icon={faLinkedin} />
            <Box mr={3}/>  */}
          {/* <HeaderIcon icon={faPhoneAlt} color="mono" fontSize={20}/>
            <Text color="mono800" variant="body2" fontWeight="semibold">For enquiry: 9946231123, 9447159965</Text> */}
          {/* </Box>
          </Grid> */}

          <Grid span={8}>
            <Box display="flex" alignItems="center" justifyContent="flex-end" height={60}>
              <Box display="flex" alignItems="center" mr={10}>
                <HeaderIcon icon={faPhoneAlt} fontSize={14} />
                <Text href="tel:9946231123" color="mono900" ml={1} fontWeight="medium" variant="body1">9946231123, 9447159965</Text>
              </Box>
              {/* <HoverBox px={2} py={1} mr={2} display="flex" alignItems="center">
                <Text variant="body1" color="mono900" fontWeight="medium" mr={1}>Products</Text>
                <ArrowIcon icon={faChevronDown} />
              </HoverBox> */}
              <Button href={Catalogue} target="_blank" appearance="primary" outline>VIEW CATALOGUE</Button>
              <Box mr={3} />
              <Button as="button" onClick={toggle} appearance="primary">Order status</Button>
            </Box>
          </Grid>
        </Container>
      </Box>
      <Modal isShowing={isShowing} toggle={toggle} overlayClassName="z-60">
        <Box bg="white" borderRadius={4} py={4} px={6} width={400} minHeight={200}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Text variant="h3" fontWeight="bold" color="mono800">Order status</Text>
            <FontAwesomeIcon
              onClick={onCloseModal}
              icon={faTimes}
            />
          </Box>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Box mt={10} />
            <Text variant="body2" fontWeight="semibold" color="mono800">Enter serial number</Text>
            <Box mt={3} />
            <TextInput
              name="order_id"
              id="order_id"
              type="text"
              ref={serialNumRef}
              className="no-outline ba br2 bc-grey-20 w-100p px-12 py-8 fs-16"
              placeholder="Serial number"
              required
            />
            {error && (
              <Text color="error" variant="caption1" mt={1}>{error.message}</Text>
            )}
            <Box mt={6} />
            <Button
              as="button"
              appearance="accent"
              loading={loading}
              block
            >
                Check status
              </Button>
          </form>
          {status && (
            <>
              <Box mt={5} />
              <Text variant="body1" color="mono800" fontWeight="bold">Order status:</Text>
              <Box display="inline-flex" borderRadius={4} px={2} py={1} mt={2} bg={status.order_id ? 'success700' : 'warning'}>
                <Text variant="caption2"textTransform="uppercase" letterSpacing="wide" fontWeight="normal" color={status.order_id ? 'white' : 'black'}>
                  {status.order_status}
                </Text>
              </Box>
              

              {status.remarks && (
             
                <Text
                 variant="body2"
                 mt={2}
                 p={4}
                 color="mono900"
                  style={{
                    fontStyle: "italic",
                    backgroundColor: '#f2f2f2'
                  }}
                >
                  Remarks: <span className="ml-2 fs-12">{status.remarks}</span>
                </Text>
              )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

Header.Mobile = MobileHeader
export default Header;