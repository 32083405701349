import React from "react";
import { Box, Container, Grid, Text } from "stormbreaker";
import Logo from "../Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  // faInstagram,
  faYoutube,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

import MobileFooter from './MobileFooter'

function Footer(props) {
  const supplyAreas = [
    "Delhi",
    "Mumbai",
    "Kolkata",
    "Chennai",
    "Hyderabad",
    "Bangalore",
    "Kochi",
    "Pondicherry",
    "Mysore",
    "Indore",
    "Mangalore",
    'Vishakhapatnam',
    "Goa",
    "Baddi",
    "Chandigarh",
    "Solan",
    "Telangana",
    "Coimbatore",
    "Pune",
    "Ahmedabad"
  ]
  return (
    <Box pt={4} bg="mono900">
      <Container maxWidth={1200} style={{ margin: "0 auto" }}>
        <Grid span={3}>
          <Logo height={80} />
          <Box mt={4} />
          <Text variant="body2" color="primary" fontWeight="bold">
            Centroid polymer technologies
          </Text>
          <Box mt={2} />
          <Text variant="body1" color="mono300">
            KINFRA Integrated Industrial & Textile Park Plot no P32(4),P32(5)
            Kanjikode, Palakkad - 678621 Kerala, India
          </Text>
        </Grid>
        <Grid span={5} pl={10}>
          <Box display="flex" alignItems="flex-start" flexDirection="column">
            <Text
              color="primary"
              fontWeight="bold"
              px={2}
              py={1}
              variant="body3"
            >
              Our products
            </Text>
            <Box mt={2} />
            <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
              <Link to={"/products/details/Silicone_o_rings_and_seals"}>
                <Text
                  color="mono300"
                  fontWeight="semibold"
                  px={2}
                  py={1}
                  variant="body1"
                >
                  Silicone O-Rings Seals & Gaskets
                </Text>
              </Link>
              <Link to={"/products/silicone_moldings"}>
                <Text
                  color="mono300"
                  fontWeight="semibold"
                  px={2}
                  py={1}
                  variant="body1"
                >
                  Silicone Seals
                </Text>
              </Link>
              <Link to={"/products/silicone_moldings"}>
                <Text
                  color="mono300"
                  fontWeight="semibold"
                  px={2}
                  py={1}
                  variant="body1"
                >
                  Silicone Gaskets
                </Text>
              </Link>
              <Link to={"/products/silicone_moldings"}>
                <Text
                  color="mono300"
                  fontWeight="semibold"
                  px={2}
                  py={1}
                  variant="body1"
                >
                  Fluorosilicone O-Rings
                </Text>
              </Link>
              <Link to={"/products/silicone_moldings"}>
                <Text
                  color="mono300"
                  fontWeight="semibold"
                  px={2}
                  py={1}
                  variant="body1"
                >
                  Silicone Over Moulding
                </Text>
              </Link>
              <Link to={"/products/silicone_hoses"}>
                <Text
                  color="mono300"
                  fontWeight="semibold"
                  px={2}
                  py={1}
                  variant="body1"
                >
                  Silicone hoses
                </Text>
              </Link>
              <Link to={"/products/silicone_extrusions"}>
                <Text
                  color="mono300"
                  fontWeight="semibold"
                  px={2}
                  py={1}
                  variant="body1"
                >
                  Silicone extruded
                </Text>
              </Link>
              <Link to={"/products/details/Silicone_sponge_or_foam"}>
                <Text
                  color="mono300"
                  fontWeight="semibold"
                  px={2}
                  py={1}
                  variant="body1"
                >
                  Silicone Sponge Extrusion
                </Text>
              </Link>
              <Link to={"/products/silicone_hoses"}>
                <Text
                  color="mono300"
                  fontWeight="semibold"
                  px={2}
                  py={1}
                  variant="body1"
                >
                  Silicone heater hose
                </Text>
              </Link>
              <Link to={"/products/silicone_hoses"}>
                <Text
                  color="mono300"
                  fontWeight="semibold"
                  px={2}
                  py={1}
                  variant="body1"
                >
                  Hand fabricated hose
                </Text>
              </Link>
              <Link to={"/products/mastic_tapes"}>
                <Text
                  color="mono300"
                  fontWeight="semibold"
                  px={2}
                  py={1}
                  variant="body1"
                >
                  Mastic tapes
                </Text>
              </Link>
              <Link to={"/products/details/PTFE_Custom_Machined_parts"}>
                <Text
                  color="mono300"
                  fontWeight="semibold"
                  px={2}
                  py={1}
                  variant="body1"
                >
                  PTFE O Rings
                </Text>
              </Link>
              <Link to={"/products/details/PTFE_Teflon_skived_strips_sheets_and_tapes"}>
                <Text
                  color="mono300"
                  fontWeight="semibold"
                  px={2}
                  py={1}
                  variant="body1"
                >
                  PTFE skived strips
                </Text>
              </Link>
              <Link to={"/products/details/PTFE_Teflon_Moulded_cylinders"}>
                <Text
                  color="mono300"
                  fontWeight="semibold"
                  px={2}
                  py={1}
                  variant="body1"
                >
                  PTFE Moulded cylinders
                </Text>
              </Link>
            </Box>
            <Box mt={5} />
          </Box>
        </Grid>

        <Grid span={4} pl={10}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            style={{ fontSize: "32px" }}
          >
            <a
              rel="noopener noreferrer"
              alt="linkedin"
              target="_blank"
              href="https://www.linkedin.com/company/centroid-polymer-technologies/?trk=public_profile_experience-item_result-card_image-click&originalSubdomain=in"
            >
              <FontAwesomeIcon icon={faLinkedin} color="#c90000" />
            </a>

            <Box mr={4} />
            <a
              rel="noopener noreferrer"
              alt="facebook"
              target="_blank"
              href="https://www.facebook.com/pages/category/Industrial-Company/Centroid-polymer-technologies-106195280739552/"
            >
              <FontAwesomeIcon icon={faFacebook} color="#c90000" />
            </a>
            <Box mr={4} />
            <a
              rel="noopener noreferrer"
              target="_blank"
              alt="twitter"
              href="https://twitter.com/CentroidPolymer"
            >
              <FontAwesomeIcon icon={faTwitter} color="#c90000" />
            </a>
            <Box mr={4} />
            {/* <a
              target="_blank"
              href="https://www.instagram.com/centroidpolymer/"
            >
              <FontAwesomeIcon icon={faInstagram} color="#c90000" />
            </a>
            <Box mr={4} /> */}
            <a
              href={"https://www.youtube.com/channel/UCVg3NilgbWhkKfDz_miMwug"}
              target="_blank"
              alt="youtube"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} color="#c90000" />
            </a>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Text variant="body2" fontWeight="medium" color="mono300" pt={10}>
              info@centroidpolymer.com
            </Text>
            <Box mt={2} />
            <Text variant="body2"   fontWeight="medium" color="mono300">
              9946231123, 9447159965
            </Text>
          </Box>
        </Grid>
      </Container>
      {/* <Box
        maxWidth={1156}
        margin="0 auto"
        display="flex"
        borderTop="0.5px solid"
        borderColor="primary600"
      /> */}
      <Container maxWidth={1200}>
        <Grid span={9}>
          <Box my={4} >
            <Text variant="body2" color="primary" fontWeight="bold" mr={4} mb={4}>
              We have services here:
            </Text>
            <Box display="flex" flexWrap="wrap">
              {supplyAreas.map(area => (
                <Text color="mono300" fontWeight="medium" variant="body1" mr={2} mb={1}>
                  {area}
                </Text>
              ))}
            </Box>
          </Box>
        </Grid>
      </Container>
      <Box bg="mono50">
        <Text
          color="mono600"
          variant="body1"
          fontWeight="normal"
          py={4}
          align="center"
        >
          © 2017-2020 Centroid Polymer. All rights reserved.
        </Text>
      </Box>
    </Box>
  );
}


Footer.Mobile = MobileFooter
export default Footer;
