import React from 'react';
import { Box, Container, Grid } from 'stormbreaker';
import Header from '../../../Components/Header';
import { Carousel, Slide } from '../../../Components/Carousel';
import SectionTitle from "../../../UIcomponents/SectionTitle";

import ContactUs from '../../../Components/ContactUs'
import { imgUrl } from './../../../images';
import TopNotch from '../../../Components/TopNotch';
import Footer from '../../../Components/Footer';
import ProductCategory from '../../../Components/ProductCategory'
import LocatedAt from '../../../Components/LocatedAt'

function MobileHomePage() {
	return (
		<>
			<Header.Mobile />
			<ContactUs.Mobile />
			<Carousel>
				<Slide.Mobile
					img={imgUrl.site_img}
					title='Why centroid polymer?'
					label='We are manufacturers of Gaskets, Seals, Rubber mouldings, Silicone Extrusions, Hoses, Rubber mastic tapes ,PTFE mouldings. We process rubber and engineering plastics using the processing technologies Moulding,extrusion and handlayup.'
				/>

				<Slide.Mobile
					img={imgUrl.silicone_rubber_extrusion}
					label='polysiloxanes or polydimethylsiloxanes is a generally non-reactive,
          stable, resistant, autoclavable elastomer to extreme environments and temperatures from −55 to
          300 °C (−67 to 572 °F).'
					title='Why Silicone rubber ?'
				/>

				<Slide.Mobile
					img={imgUrl.mastic_tape_vaccum_bagging[0]}
					title='WHY Butyl Mastic tapes?'
					label='Butyl mastic tapes are butyl rubber based self-adhesive tapes with a high tack and self fusing
          property and covers a wide range of temperatures (both high and low temperature).'
				/>

				<Slide.Mobile
					title='WHY PTFE / Teflon?'
					label='Polytetrafluoroethylene (PTFE) is a synthetic fluoropolymer of tetrafluoroethylene. Also known as
          Teflon, it is resistant to high temperature and chemicals, best known for low friction, hydrophobic
          and electrical insulation.'
					img={imgUrl.ptfe_machined_parts[3]}
				/>
			</Carousel>
			<Box mt={7} />
			<TopNotch.Mobile />
			<Box mt={10} />
			<Box px={4}>
				<SectionTitle.Mobile title='Our featured products' />
			</Box>		
			<Box mt={5} />
			<ProductCategory.Mobile />
			<Box mt={10} />
			<Box px={4}>
				<SectionTitle.Mobile title='WE ARE LOCATED AT' />
			</Box>		
			<Box mt={5} />
			<LocatedAt.Mobile />
			<Footer.Mobile />
		</>
	);
}

export default MobileHomePage;
