import React from "react";
import ReactSlider from "react-slick";
import styled from "@emotion/styled";

import { ReactComponent as RightIcon } from "./icons/right.svg";
import { ReactComponent as LeftIcon } from "./icons/left.svg";

const RightHandle = styled(RightIcon)`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  height: ${props =>
    props.arrowSize ? props.theme.sizeOfSpace(props.arrowSize) : ""};
  width: ${props =>
    props.arrowSize ? props.theme.sizeOfSpace(props.arrowSize) : ""};
`;

const LeftHandle = styled(LeftIcon)`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  height: ${props =>
    props.arrowSize ? props.theme.sizeOfSpace(props.arrowSize) : ""};
  width: ${props =>
    props.arrowSize ? props.theme.sizeOfSpace(props.arrowSize) : ""};
`;

const Slider = props => {
  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <RightHandle arrowSize={props.arrowSize} />,
    prevArrow: <LeftHandle arrowSize={props.arrowSize} />
    // adaptiveHeight: true,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 2,
    //     }
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2
    //     }
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1
    //     }
    //   }
    // ]
  };

  return (
    <ReactSlider {...settings} {...props.settings}>
      {props.children}
    </ReactSlider>
  );
};

export default Slider;
