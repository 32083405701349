import React from 'react';
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import Media from 'react-media';

import HomePage from './Pages/Home';
// import ProductsPage from "./Pages/Products/AllProducts";
import ProductPage from './Pages/Products/ProductPage';
import DetailsPage from './Pages/Products/DetailsPage';
import AdminPage from './Pages/Admin';

import MobileHomePage from './Pages/mobile/Home';
import MobileProductPage from './Pages/mobile/Products';
import MobileDetailsPage from './Pages/mobile/Details';

export const AppRoutes = (
	<Router basename='/'>
		<Media query='(max-width: 1200px)'>
			{(matches) =>
				matches ? (
					<Switch>
						<Route path='/' exact component={MobileHomePage} />
						<Route exact path='/products/:id' component={MobileProductPage} />
						<Route exact path='/products/details/:id' component={MobileDetailsPage} />
					</Switch>
				) : (
					<Switch>
						<Route path='/' exact component={HomePage} />
						<Route exact path='/products/:id' component={ProductPage} />
						<Route exact path='/products/details/:id' component={DetailsPage} />
						<Route exact path='/status_entry' component={AdminPage}></Route>
						<Redirect to='/' />
					</Switch>
				)
			}
		</Media>
	</Router>
);
