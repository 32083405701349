import React from "react";
import { Container, Box, Grid, Text } from "stormbreaker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { faPlaneDeparture, faTruck, faBuilding, faSyringe, faGlassCheers, faChargingStation, faCapsules, faBurn } from "@fortawesome/free-solid-svg-icons";

const StyledIcon = styled(FontAwesomeIcon) `
  font-size: 32px;
  color: ${props => props.theme.colors.primary};
  :hover{
    color: ${props => props.theme.colors.success800};
  }
  transition: color 0.3s;
  cursor: pointer;
`

function MarketingArea() {
  return (
    <Container maxWidth={1200}>
      <Grid>
        <Box bg="mono50" borderRadius={12} py={6}>
          <Container gap={20} maxWidth={1000}>
            <Grid span={6}>
              <Box mt={6} />
              <Box display="flex">
                <Box>
                  <Box borderRadius="50%" height={80} width={80} bg="mono100" display="flex" alignItems="center" justifyContent="center">
                    <StyledIcon icon={faPlaneDeparture}/>
                  </Box>
                </Box>
                <Box mr={5} />
                <Box display="flex" flexDirection="column">
                  <Text variant="body3">Aerospace</Text>
                  <Box pt={1} />
                  <Text variant="body1" fontWeight="light" color="mono400">
                    High performance of silicone rubber makes it a reliable
                    material in aerospace, aviation like gaskets, seals, hoses
                    and tapes with AS, SAE standards.
                  </Text>
                  <Box pt={3} />
                 {/* <Box>
                    <Button outline appearance="accent">
                      SEE PRODUCTS{" "}
                    </Button>
                  </Box> */}
                </Box>
              </Box>
              <Box mt={3} />
            </Grid>
            <Grid span={6}>
            <Box mt={3} />
              <Box display="flex">
                <Box>
                  <Box borderRadius="50%" height={80} width={80} bg="mono100" display="flex" alignItems="center" justifyContent="center">
                    <StyledIcon icon={faTruck}/>
                  </Box>
                </Box>
                <Box mr={5} />
                <Box display="flex" flexDirection="column">
                  <Text variant="body3">Automotive</Text>
                  <Box pt={1} />
                  <Text variant="body1" fontWeight="light" color="mono400">
                    Our range of Silicone Rubber seals, gaskets, heater hoses
                    plays a vital role in automotive performance. For Electric
                    Vehicles(EV’s) the flame retardant and high insulation
                    property makes it essential.
                  </Text>
                  <Box pt={3} />
                  {/*{/* <Box>
                    <Button outline appearance="accent">
                      SEE PRODUCTS{" "}
                    </Button>
                  </Box> */}
                </Box>
              </Box>
              <Box mt={3} />
            </Grid>
            <Grid span={6}>
            <Box mt={3} />
              <Box display="flex">
                <Box>
                <Box borderRadius="50%" height={80} width={80} bg="mono100" display="flex" alignItems="center" justifyContent="center">
                    <StyledIcon icon={faBuilding}/>
                  </Box>
                </Box>
                <Box mr={5} />
                <Box display="flex" flexDirection="column">
                  <Text variant="body3">Construction</Text>
                  <Box pt={1} />
                  <Text variant="body1" fontWeight="light" color="mono400">
                    Butyl Mastic tapes for roofing, mastic tapes for tank
                    waterproofing. Silicone gaskets and beadings for windows and
                    doors.
                  </Text>
                  <Box pt={3} />
                 {/* <Box>
                    <Button outline appearance="accent">
                      SEE PRODUCTS{" "}
                    </Button>
                  </Box> */}
                </Box>
              </Box>
              <Box mt={6} />
            </Grid>
            <Grid span={6}>
            <Box mt={3} />
              <Box display="flex">
                <Box>
                <Box borderRadius="50%" height={80} width={80} bg="mono100" display="flex" alignItems="center" justifyContent="center">
                    <StyledIcon icon={faSyringe}/>
                  </Box>
                </Box>
                <Box mr={5} />
                <Box display="flex" flexDirection="column">
                  <Text variant="body3">Medical</Text>
                  <Box pt={1} />
                  <Text variant="body1" fontWeight="light" color="mono400">
                    Silicone tubes, hoses, mouldings are used in pharma
                    industries in filling machines, sterlizer or autoclave
                    gasket and other high end applications
                  </Text>
                  <Box pt={3} />
                 {/* <Box>
                    <Button outline appearance="accent">
                      SEE PRODUCTS{" "}
                    </Button>
                  </Box> */}
                </Box>
              </Box>
              <Box mt={3} />
            </Grid>
            <Grid span={6}>
            <Box mt={3} />
              <Box display="flex">
                <Box>
                <Box borderRadius="50%" height={80} width={80} bg="mono100" display="flex" alignItems="center" justifyContent="center">
                    <StyledIcon icon={faGlassCheers}/>
                  </Box>
                </Box>
                <Box mr={5} />
                <Box display="flex" flexDirection="column">
                  <Text variant="body3">Food & Beverages</Text>
                  <Box pt={1} />
                  <Text variant="body1" fontWeight="light" color="mono400">
                    Food grade silicone tubes and gaskets are custom
                    manufactured. Mainly used in diary, milk processing and
                    vending machines
                  </Text>
                  <Box pt={3} />
                 {/* <Box>
                    <Button outline appearance="accent">
                      SEE PRODUCTS{" "}
                    </Button>
                  </Box> */}
                </Box>
              </Box>
              <Box mt={3} />
            </Grid>
            <Grid span={6}>
            <Box mt={3} />
              <Box display="flex">
                <Box>
                 <Box borderRadius="50%" height={80} width={80} bg="mono100" display="flex" alignItems="center" justifyContent="center">
                    <StyledIcon icon={faChargingStation}/>
                  </Box>
                </Box>
                <Box mr={5} />
                <Box display="flex" flexDirection="column">
                  <Text variant="body3">Electrical and Electronics</Text>
                  <Box pt={1} />
                  <Text variant="body1" fontWeight="light" color="mono400">
                    Mastic tapes for cable jointing and insulations are a future
                    in insulation. Conventional conductor line covers and
                    sleeves are replaced with silicone overhead covers which
                    does not require any tools.
                  </Text>
                  <Box pt={3} />
                 {/* <Box>
                    <Button outline appearance="accent">
                      SEE PRODUCTS{" "}
                    </Button>
                  </Box> */}
                </Box>
              </Box>
              <Box mt={3} />
            </Grid>
            <Grid span={6}>
            <Box mt={3} />
              <Box display="flex">
                <Box>
                <Box borderRadius="50%" height={80} width={80} bg="mono100" display="flex" alignItems="center" justifyContent="center">
                    <StyledIcon icon={faCapsules}/>
                  </Box>
                </Box>
                <Box mr={5} />
                <Box display="flex" flexDirection="column">
                  <Text variant="body3">Pharma and Life sciences</Text>
                  <Box pt={1} />
                  <Text variant="body1" fontWeight="light" color="mono400">
                    Tubes, hoses for cell culture, laboratory uses are chosen
                    from silicone rubber due to their performance.
                  </Text>
                  <Box pt={3} />
                 {/* <Box>
                    <Button outline appearance="accent">
                      SEE PRODUCTS{" "}
                    </Button>
                  </Box> */}
                </Box>
              </Box>
              <Box mt={3} />
            </Grid>
            <Grid span={6}>
            <Box mt={3} />
              <Box display="flex">
                <Box>
                <Box borderRadius="50%" height={80} width={80} bg="mono100" display="flex" alignItems="center" justifyContent="center">
                    <StyledIcon icon={faBurn}/>
                  </Box>
                </Box>
                <Box mr={5} />
                <Box display="flex" flexDirection="column">
                  <Text variant="body3">Oil and Gas</Text>
                  <Box pt={1} />
                  <Text variant="body1" fontWeight="light" color="mono400">
                    PTFE parts are used in sealing and packing components.
                    Excellent resistance to chemicals and heat makes it a major
                    component.
                  </Text>
                  <Box pt={3} />
                 {/* <Box>
                    <Button outline appearance="accent">
                      SEE PRODUCTS{" "}
                    </Button>
                  </Box> */}
                </Box>
              </Box>
              <Box mt={3} />
            </Grid>
          </Container>
        </Box>
      </Grid>
    </Container>
  );
}

export default MarketingArea;
