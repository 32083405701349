import React, { createContext, useState } from "react";
import {imgUrl} from '../images'

export const ProductCategoryContext = createContext([{}, () => {}]);

function ProductCategoryContextProvider({ children }) {
  const categoryList = [
    {
      name: "Silicone hoses",
      id: "silicone_hoses",
      description: "Silicone hoses of food grade and heater hoses for automotive, aerospace applications of high quality silicone rubber supplied in standard and custom sizes.",
      imgUrl: imgUrl.silicone_hoses
    },
    {
      name: "Silicone Extrusions",
      id: "silicone_extrusions",
      description: "High quality silicone extrusions of all custom shapes are manufactured. Flame retardant, High tear and tensile strength silicones are used and all custom profiles are supplied.",
      imgUrl: imgUrl.silicone_profiles[2]
    },
    {
      name: "Silicone Tubings",
      id: "silicone_tubings",
      description: "Silicone tubes for food grade and pharma applications are manufactured in a clean environment.All standard sizes and custom sizes are manufactured.",
      imgUrl: imgUrl.tubing_platinum_grade[2]
    },
    {
      name: "Silicone Moulded or Molded",
      id: "silicone_moldings",
      description: "Custom Mouldings like Bellows, boots, diaphragm, seals, gaskets, overmoldings are designed and molded meeting the food, Medical and aerospace standards",
      imgUrl: imgUrl.silicone_custom_moldings[0]
    },
    {
      name: "Mastic Tapes",
      id: "mastic_tapes",
      description: "Butyl mastic tapes for cable jointing kits like black, red and stress grade mastics are manufactured. Mastic tapes for roofing, vaccum bagging are supplied in rolls.",
      imgUrl: imgUrl.black_mastic_tape[1]
    },
    {
      name: "PTFE /Teflon Parts",
      id: "ptfe_teflone",
      description: "PTFE / Teflon parts like molded cylinder, custom machined gaskets, seals and o rings are manufactured and supplied in industry standards.",
      imgUrl: imgUrl.ptfe_machined_parts[3]
    }
  ];
  const categoryHook = useState(categoryList);

  return (
    <ProductCategoryContext.Provider value={categoryHook}>
      {children}
    </ProductCategoryContext.Provider>
  );
}

export default ProductCategoryContextProvider;
